<template>
    <v-card flat class="crd-cls pa-4">
      <v-row>
        <v-col cols="6">
          <span class="yr-cls">{{ moment().format("YYYY") }}</span>
        </v-col>
        <v-col cols="6" class="d-flex align-center justify-end">
          <span v-for="item in variationDate" :key="item">
            <span
              class="gap show-cursor"
              :style="{ color: item.value == selection ? 'blue' : '' }"
              @click="getData(item.value)"
              >{{ item.data }}</span
            >
            <span v-if="item.value != 'month'" class="gap">|</span>
          </span>
        </v-col>
      </v-row>
      <v-row class="mt-2">
        <v-col cols="3">
          <v-row>
            <v-col cols="6" class="d-flex flex-column">
              <span class="label">Vacation</span>
              <span class="hrs-cls mt-2">{{ getHourData("VAC") }} Hours</span>
            </v-col>
            <v-col cols="6" class="d-flex flex-column">
              <span class="label">PTO</span>
              <span class="hrs-cls mt-2">{{ getHourData("PTO") }} Hours</span>
            </v-col>
            <v-col cols="6" class="d-flex flex-column">
              <span class="label">Requests</span>
              <span class="hrs-cls mt-2"
                >{{
                  postVacationHour && postVacationHour.requested
                    ? postVacationHour.requested
                    : 0
                }}
                <!-- /{{
                  postVacationHour && postVacationHour.rejected
                    ? postVacationHour.rejected
                    : 0
                }} -->
                Hours</span
              >
            </v-col>
            <v-col cols="6" class="d-flex flex-column">
              <span class="label">Approved</span>
              <span class="hrs-cls mt-2"
                >{{
                  postVacationHour && postVacationHour.pipeline
                    ? postVacationHour.pipeline
                    : 0
                }}
                Hours</span
              >
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="1">
          <div class="vl"></div>
        </v-col>
        <v-col cols="2" class="d-flex flex-column justify-center">
          <!-- <v-row>      
                      <v-col cols="12" class="d-flex flex-column">
                          <span class="label">Planned Vacation</span>
                          <span class="hrs-cls mt-2">40 Hours / 5 People</span>
                      </v-col>                  
                      <v-col cols="12" class="d-flex flex-column">
                          <span class="label">Unplanned Vacation</span>
                          <span class="hrs-cls mt-2">10 Hours / 5 People</span>
                      </v-col>
                  </v-row> -->
          <v-row>
            <v-col cols="12" class="d-flex flex-column">
              <span class="label">Planned Vacation</span>
              <span class="hrs-cls mt-2"
                >{{
                  postVacationHour && postVacationHour.planned
                    ? postVacationHour.planned
                    : 0
                }}
                Hours
              </span>
            </v-col>
            <v-col cols="12" class="d-flex flex-column">
              <span class="label">Unplanned Vacation</span>
              <span class="hrs-cls mt-2"
                >{{
                  postVacationHour && postVacationHour.un_planned
                    ? postVacationHour.un_planned
                    : 0
                }}
                Hours</span
              >
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="3">
          <v-row>
            <v-col cols="12">
              <span>
                <LineChart :selection="selection" />
              </span>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="3" class="d-flex justify-center align-center">
          <v-row>
            <v-col cols="12">
              <span class="label"
                >{{ !blockedDate || blockedDate.length == 0 ? "No" : "" }} Blocked
                Calendar</span
              >
            </v-col>
            <v-col cols="12" v-for="item of blockedDate" :key="item">
              <span class="blocked-date"
                >{{ moment(item.from_date).format("DD-MM-YYYY") }} TO
                {{ moment(item.to_date).format("DD-MM-YYYY") }}</span
              >
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
  </template>
  <script>
  import LineChart from "./LineChart.vue";
  import moment from "moment";
  import VacationService from "@/api/service/Vacation/VacationService";
  import EmployeeVacationAllotmentService from "@/api/service/EmployeeVacationAllotment/EmployeeVacationAllotmentService";
  import BlockedCalendarService from "@/api/service/BlockedCalendar/BlockedCalendarService";
  export default {
    props: ["userData", "boardOption"],
    components: {
      LineChart,
    },
    data() {
      return {
        vacationData: [],
        blockedDate: [],
        planned: null,
        postVacationHour: null,
        unPlanned: null,
        selection: "today",
        variationDate: [
          {
            value: "today",
            data: "Today",
          },
          {
            value: "week",
            data: "This Week",
          },
          {
            value: "month",
            data: "This month",
          },
        ],
      };
    },
    watch: {
      boardOption() {
        if (this.boardOption) {
          this.getPostVacationHour();
          this.getVacationBDData();
          this.getBlockedData();
        }
      },
      userData() {
        if (this.userData) {
          this.getPostVacationHour();
          this.getVacationBDData();
          this.getBlockedData();
        }
      },
    },
    created() {
      if (this.userData) {
        this.getPostVacationHour();
        this.getVacationBDData();
        this.getBlockedData();
      }
    },
    methods: {
      moment,
      async getData(value) {
        this.selection = value;
        this.getBlockedData();
      },
      async getPostVacationHour() {
        try {
          let obj = {
            userId: this.userData ? this.userData.id : null,
          };
          let res = await VacationService.getVacationHour(obj);
          this.postVacationHour = res && res.length > 0 ? res[0] : null;
          console.log(this.postVacationHour,'this.postVacationHour');
        } catch (exception) {
          this.postVacationHour = null;
        }
      },
      async getBlockedData() {
        try {
          let obj = {
            option: this.selection,
            userId: this.userData ? JSON.stringify([this.userData.id]) : null,
          };
          let res = await BlockedCalendarService.getBlockedCalenderData(obj);
          this.blockedDate = res && res.length > 0 ? res : [];
        } catch (exception) {
          console.log(exception);
        }
      },
      async getVacationBDData() {
        try {
          let obj = {};
          obj.userId = this.userData ? this.userData.id : null;
          let res = await EmployeeVacationAllotmentService.vacationDashboardData(
            obj
          );
          this.vacationData = res && res.length > 0 ? res : [];
          console.log(this.vacationData,'vacationDatavacationData');
          
        } catch (e) {
          console.log(e);
          this.vacationData = [];
        }
      },
      getHourData(type) {
        if (type) {
          console.log(type);
          let data = this.vacationData.filter((k) => k.code == type);
          data = data.reduce((sum, item) => sum + item.available_qty, 0);
          type == 'VAC'? this.$store.commit('SET_VACATION_DATA',data) : this.$store.commit('SET_PTO_DATA',data)
          return data
        }
      },
    },
  };
  </script>
  <style scoped>
  .crd-cls {
    border: 1px solid #c6c6d1;
    background-color: #f3f2f5;
    border-radius: 12px;
  }
  .yr-cls {
    font-size: 16px;
    font-weight: 700;
    line-height: 19.36px;
    text-align: left;
  }
  .label {
    font-size: 14px;
    font-weight: 600;
    line-height: 16.94px;
    text-align: left;
    color: #000000;
  }
  .hrs-cls {
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    text-align: left;
  }
  .gap {
    margin: 0 8px;
  }
  
  .gap:first-child {
    margin-left: 0;
  }
  
  .gap:last-child {
    margin-right: 0;
  }
  .vl {
    border-left: 1px solid #c6c6c7;
    height: 100%;
  }
  .blocked-date {
    font-size: 14px;
    font-weight: 500;
    background-color: #efd3d3;
    width: fit-content;
    border-radius: 25px;
    padding: 8px 10px;
  }
  </style>
  