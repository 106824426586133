import { createApp } from 'vue'
import Vuex from 'vuex'
import SocketHandler from '@/api/service/SocketHandler'
createApp(Vuex)

export default new Vuex.Store({
    state: {
        defaultIssueInView:null,
        startIssueId: null,
        reloadActivity:null,
        refreshBreadCrumps:false,
        inProgress:false,
        toPending:false,
        stageInProgress:false,
        hideTimer:false,
        snackbar:{},
        refreshTimeSheet:false,
        refreshWorkSpace:false,
        showLoader:false,
        showOtDialog:false,
        showNormal:false,
        showExist:false,
        sessionExpire:false,
        issueDisplay:'',
        timeReload : false,
        deviceId:null,
        shiftInDue:null,
        logoutAction:false,
        changeMySpace:false,
        assigneeChanged:false,
        qaTaskCompleted:false,
        addQaTask:false,
        issueDroped:false,
        notificationId:null,
        reloadTheIssue:false,
        reloadSubTask:false,
        issueViewDrawer:false,
        reloadForums:false,
        reloadIssue:0,
        issueCreated:false,
        issueUpdated:false,
        updateWidget:false,
        issueId:null,
        reload:false,
        candidateId:null,
        inBetweenTime:false,
        issueStatus:null
    },
    mutations: {
        SET_CANDIDATE_ID(state,payload){
            state.candidateId = payload
        },
        SET_ISSUE_STATUS(state,payload){
            state.issueStatus = payload
        },
        SET_SUMMARY_RELOAD(state,payload){
            state.reload = payload
        },
        SET_SHIFT_IN_DUE(state,payload){
            state.shiftInDue = payload
        },
        RELOAD_THE_ISSUE(state,payload){
            state.reloadTheIssue = payload
        },
        RELOAD_THE_SUB_TASK(state,payload){
            state.reloadSubTask = payload
        },
        SET_NOTIFY_ID(state,payload){
            state.notificationId = payload
        },
        CHANGE_MY_SPACE(state,payload){
            state.changeMySpace = payload
        },
        CHANGE_ASSIGNEE(state,payload){
            state.assigneeChanged = payload
        },
        QATASK_CHANGE(state,payload){
            state.qaTaskCompleted = payload
        },        
        ADD_QA_TASK(state,payload){
            state.addQaTask = payload
        },
        ISSUE_CHANGE(state,payload){
            state.issueDroped = payload
        },
        SET_LOGOUT_ACTION(state,payload) {
            state.logoutAction = payload
        },
        SET_DEVICE_ID(state,payload) {
            state.deviceId = payload
        },
        SET_START_ISSUE_ID(state, payload) {
            state.startIssueId = payload
        },
        RELOAD_ACTIVITY(state, payload) {
            state.reloadActivity = payload
        },
        WORK_SPACE_ID(state, payload) {
            state.workSpaceId = payload
        },
        PROJECT_ID(state, payload) {
            state.projectId = payload
        },
        ACCOUNT_ID(state, payload) {
            state.accountId = payload
        },
        SET_SNACKBAR(state,snackbar){
            state.snackbar= snackbar
        },
        SET_REFRESH_BREADCRUMP(state,refreshBreadCrumps) {
            state.refreshBreadCrumps=refreshBreadCrumps
        },
        SET_IN_PROGRESS(state,payload) {
            state.inProgress = payload
        },
        SET_TO_PENDING(state,payload) {
            state.toPending = payload
        },
        SET_STAGE_IN_PROGRESS(state,payload) {
            state.stageInProgress = payload
        },
        SET_IN_BETWEEN_TIME(state,payload) {
            state.inBetweenTime = payload
        },
        SET_HIDE_TIMER(state,payload) {
            state.hideTimer = payload
        },
        SET_LOADER(state,payload) {
            state.showLoader = payload
        },
        SET_OT_DIALOGUE(state,payload) {
            state.showOtDialog = payload
        },
        SET_NORMAL(state,payload) {
            state.showNormal = payload
        },
        SET_EXIST(state,payload) {
            state.showExist = payload
        },
        SET_ISSUE_DISPLAY(state,payload) {
            state.issueDisplay = payload
        },
        SET_EXPIRE_SESSION(state,payload) {
            state.sessionExpire = payload
        },
        SET_REFRESH_TIME_SHEET(state,payload) {
            state.refreshTimeSheet = payload
        },
        SET_REFRESH_WORK_SPACE(state,payload) {
            state.refreshWorkSpace = payload
        },
        SET_TIME_RELOAD(state,payload) {
            state.timeReload = payload
        },
        SET_DEFAULT_ISSUE_IN_VIEW(state,payload ) {
            state.defaultIssueInView = payload
        },
        SET_ISSUE_VIEW_DRAWER(state,payload) {
            state.issueViewDrawer = payload
        },
        SET_ISSUE_UPDATED(state,payload) {
            state.reloadIssue=payload
        },
        RELOAD_FORUMS(state,payload) {
            state.reloadForums=payload
        },
        SET_OLD_ISSUE_UPDATED(state,payload) {
            state.issueUpdated=payload
        },
        SET_ISSUE_CREATED(state,payload) {
            state.issueCreated=payload
        },
        SET_WIDGET_UPDATED(state,payload) {
            state.updateWidget=payload
        },
        SET_UPDATED_ISSUE_ID(state,payload) {
            state.issueId=payload
        }
    },
    getters: {
        getCandidateId(state) {
            return state.candidateId
        },
        getIssueStatus(state) {
            return state.issueStatus
        },
        getRefreshSummary(state) {
            return state.reload
        },
        getIssueUpdated(state) {
            return state.reloadIssue
        },
        getIssueViewDrawer(state) {
            return state.issueViewDrawer
        },
        getDefaultIssueInView(state){
            return state.defaultIssueInView;
        },
        getLogoutAction(state) {
            return state.logoutAction
        },
        getNotificationId(state) {
            return state.notificationId
        },
        getShiftInDue(state) {
            return state.shiftInDue
        },
        getDeviceId(state) {
            return state.deviceId
        },
        getStartIssueId(state) {
            return state.startIssueId
        },
        getWorkSpaceId(state) {
            return state.workSpaceId
        },
        getAccountId(state) {
            return state.accountId
        },
        getProjectId(state) {
            return state.projectId
        },
        getReloadActivity(state) {
            return state.reloadActivity
        },
        getRefreshBreadCrump(state) {
            return state.refreshBreadCrumps
        },
        getInProgress(state) {
            return state.inProgress
        },
        getInBetweenTime(state) {
            return state.inBetweenTime
        },
        getPending(state) {
            return state.toPending
        },
        getStageInProgress(state) {
            return state.stageInProgress
        },
        getHideTimer(state) {
            return state.hideTimer
        },
        getLoader(state) {
            return state.showLoader
        },
        getOtDialog(state) {
            return state.showOtDialog
        },
        getNormal(state) {
            return state.showNormal
        },
        getExist(state) {
            return state.showExist
        },
        getIssueDisplay(state) {
            return state.issueDisplay
        },
        getSessionData(state) {
            return state.sessionExpire
        },
        getRefreshTimeSheet(state) {
            return state.refreshTimeSheet
        },
        getRefreshWorkSpace(state) {
            return state.refreshWorkSpace
        },
        getTimeReload(state) {
            return state.timeReload
        },
        getMyspace(state){
            return state.changeMySpace
        },
        getAssignee(state){
            return state.assigneeChanged
        },
        getQaUpdate(state){
            return state.qaTaskCompleted
        },
        getQaAdd(state){
            return state.addQaTask
        },
        getIssueUpdate(state){
            return state.issueDroped
        },
        getReloadTheIssue(state){
            return state.reloadTheIssue
         },
        getReloadSubTask(state){
             return state.reloadSubTask
         },
        getReloadForums(state){
            return state.reloadForums
        },
        getUpdatedIssueStatus(state){
            return state.issueUpdated
        },
        getCreatedIssueStatus(state){
            return state.issueCreated
         },
        getUpdatedWidget(state){
            return state.updateWidget
         },
        getUpdatedIssueId(state){
             return state.issueId
         }, 

    },
    actions: {
        
        issueUpdated(context,$issueId) {
            context.commit('SET_ISSUE_UPDATED',$issueId)
        },
        setIssueInView(context,$issueId) {
            context.commit('SET_DEFAULT_ISSUE_IN_VIEW',$issueId)
            context.commit('SET_ISSUE_VIEW_DRAWER',true)
           // alert('dispatched')
        },
        setSnackbar({commit}, snackbar){
            snackbar.showing =true
            snackbar.color= snackbar.color || '#3C5AAA'
            commit('SET_SNACKBAR',snackbar);
        },
        issueViewed(context,$issueId) {
            console.log(context)
            SocketHandler.messageViewed($issueId)
        },
        sendNotifications() {
                
        }
    },
    modules: {}
})
// this.$store.commit('setAuthentication',true);
