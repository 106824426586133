<template>
  <v-layout>
    <v-navigation-drawer id="side-menu" v-model="drawer" location="left" fixed :key="navDrawerPinned" :rail="rail"
      mobile-breakpoint="0" :style="!rail ? 'width:210px' : 'width:55px'" style="
        position: fixed;
        top: 72px;
        height: 90vh;
        z-index: 0;
        transform: translateX(0%);
      " class="side-menu">
      <v-btn @click.stop="stopRail()" v-on:mouseleave="onMouseLeave" v-on:mouseover="onMouseHover" style="position: fixed;
                    background: white;
                    border:1px solid
                    cursor:pointer;
                    top:15px;
                    overflow:auto;
                    width:25px;
                    height: 25px; 
                    zoom:80%;
                    z-index: 9999;
                    transition: transform 0.5s ease-in-out;" :style="{ transform: transformValue, left: leftMargin }"
        variant="text" icon="mdi-chevron-left">
      </v-btn>

      <v-list v-model:opened="open" class="mt-2" :style="rail ? { 'padding-top': '60%', 'padding-bottom': '60%' } : ''">
        <!--   <div v-for="menu in menuOptions" :key="menu.code">
            <v-list-subheader v-if="!menu.route" class="menu-group">{{ menu.name }}</v-list-subheader>
            <v-list-item 
            class="menu-item"
            density="compact"
            v-if="menu.route "
               :value="menu.name" :title="menu.name"
                :prepend-icon="icon">
        </v-list-item> 
        </div> -->
        <div v-for="item in mainMenu" :key="item">
          <v-list-item class="menu-item" density="compact" v-if="!item.hideMenu" @click="checkRoute(item)"
            :value="item.value" :title="item.title" :active-class="item.active ? 'select-menu' : ''"
            :class="[, item.active ? 'select-menu' : '']">
            <template v-slot:prepend>
              <v-tooltip :text="item.title">
                <template v-slot:activator="{ props }">
                  <span class="pr-3" v-bind="props">
                    <v-icon v-if="item.icon"> mdi-home-outline </v-icon>
                    <img :src="item.imageUrl" v-else style="width: 16px; height: 16px" />
                  </span>
                </template>
              </v-tooltip>
            </template>
          </v-list-item>
        </div>

        <!-- <v-list-item class="menu-item" density="compact" @click="$router.push('/projectList')" value="home"
          title="Projects">
          <template v-slot:prepend>
            <v-tooltip text="Projects">
              <template v-slot:activator="{ props }">
                <span class="pr-3" v-bind="props">
                  <v-icon>
                    mdi mdi-file-tree-outline
                  </v-icon>
                </span>
              </template>
            </v-tooltip>
          </template>
        </v-list-item>




        <v-list-item class="menu-item" density="compact" @click="$router.push('/accountList')" value="home"
          title="Account">
          <template v-slot:prepend>
            <v-tooltip text="Account">
              <template v-slot:activator="{ props }">
                <span class="pr-3" v-bind="props">
                  <v-icon>
                    mdi-account-multiple-outline
                  </v-icon>
                </span>
              </template>
            </v-tooltip>
          </template>
        </v-list-item>




        <v-list-item class="menu-item" density="compact" @click="$router.push('/workspace')" value="home"
          title="Workspace">
          <template v-slot:prepend>
            <v-tooltip text="Workspace">
              <template v-slot:activator="{ props }">
                <span class="pr-3" v-bind="props">
                  <v-icon>
                    mdi mdi-family-tree
                  </v-icon>
                </span>
              </template>
            </v-tooltip>
          </template>

        </v-list-item> -->

        <v-divider />
        <div v-for="item of menuData" :key="item">
          <v-list-subheader v-if="!rail" class="menu-group" :class="[, item.active ? 'select-menu' : '']"
            :value="item.name">
            <v-icon @click="item.expand = !item.expand">
              {{ item.expand ? "mdi-chevron-down" : "mdi-chevron-right" }}
            </v-icon>
            {{ item.name }}
          </v-list-subheader>
          <template v-for="subItem in item.subMenu" :key="subItem">
            <v-list-item class="menu-item" :active-class="subItem.active ? 'select-menu' : ''" :class="[
              !rail ? 'menu-item-left' : '',
              subItem.active ? 'select-menu' : '',
            ]" v-if="item.expand || rail" density="compact" @click="MenuRoute(subItem)" :value="subItem.name"
              :title="item.name">
              <template v-slot:prepend>
                <v-tooltip :text="subItem.name">
                  <template v-slot:activator="{ props }">
                    <span class="pr-3 pt-2" v-bind="props">
                      <img :src="subItem.icon" style="width: 16px; height: 16px" />
                    </span>
                  </template>
                </v-tooltip>
              </template>
              <template v-slot:title>
                {{ subItem.name }}
              </template>
            </v-list-item>
          </template>
        </div>
        <v-divider />
        <!--   <div v-for="item of menuData" :key="item">
            <v-list-group :value="item.name" :prepend-icon="item.icon">
              <template v-slot:activator="{ props }">
                <v-list-item style="text-align: left;" 
                v-bind="props" class="menu-group">
              {{ item.name }}</v-list-item>
              </template>
              <v-list-item v-for="subItem in item.subMenu" 
              @click="MenuRoute(subItem)" 
              :key="subItem" :value="subItem.name" :title="subItem.name"
                :prepend-icon="icon"></v-list-item>
            </v-list-group>
          </div> -->
      </v-list>
    </v-navigation-drawer>
  </v-layout>
</template>
<script>
import ActivityGroupService from "@/api/service/ActivityGroup/ActivityGroupService";
import MenuService from "@/api/service/Menu/MenuService";
import RolePermissionService from "@/api/service/RolePermission/RolePermissionService";
//import UserService from '@/api/service/UserService';
import lodash from "lodash";
export default {
  computed: {
    transformValue() {
      return this.rail ? "rotate(180deg)" : "rotate(0deg)";
    },
    leftMargin() {
      return this.rail ? "55px" : "247px";
    },
  },
  data: () => ({
    drawer: true,
    users: {},
    activities: [],
    navDrawerPinned: false,
    rail: true,
    menu: [],
    menuOptions: [],
    open: ["Users"],
    menuData: [],
    mainMenu: [
      {
        title: "Home",
        value: "home",
        route: "/",
        icon: "mdi-home-outline",
        active: false,
        hideMenu: false,
      },
      {
        title: "Process Lead Dashboard",
        value: "processLeadDashboard",
        route: "/processLead",
        imageUrl:
          "https://synergy-snaps.s3.ap-south-1.amazonaws.com/public/icons/Board_gray.svg ",
        active: false,
        hideMenu: false,
      },
      {
        title: "Past Due",
        value: "backlog",
        route: "/issueconsolidated?pastdue=true",
        imageUrl:
          "https://synergy-snaps.s3.ap-south-1.amazonaws.com/public/icons/Backlog_gray.svg ",
        active: false,
        hideMenu: false,
      },
      {
        title: "Critical",
        value: "critical",
        route: "/issueconsolidated?priority=critical",
        imageUrl:
          "https://synergy-snaps.s3.ap-south-1.amazonaws.com/public/icons/Executive+Management_gray.svg",
        active: false,
        hideMenu: false,
      },
      {
        title: "Board",
        value: "board",
        route: "/issueconsolidated",
        imageUrl:
          "https://synergy-snaps.s3.ap-south-1.amazonaws.com/public/icons/Board_gray.svg ",
        active: false,
        hideMenu: false,
      },
      {
        title: "Custom Board",
        value: "customBoard",
        route: "/dashboardwidgets",
        imageUrl:
          "https://synergy-snaps.s3.ap-south-1.amazonaws.com/public/icons/dash.svg ",
        hideMenu: false,
      },
      {
        title: "Team Lead Dashboard",
        value: "teamleaddashboard",
        route: "/productionDashboard",
        imageUrl: "https://synergy-snaps.s3.ap-south-1.amazonaws.com/public/Tl.svg",
        hideMenu: true,
      },
      // {
      //   title: "Escalation Dashboard",
      //   value: "escalationdashboard",
      //   route: "/escalationbaord",
      //   imageUrl: "https://synergy-snaps.s3.ap-south-1.amazonaws.com/public/icons/Executive+Management_gray.svg",
      //   hideMenu: false,
      // },
      // {
      //   title: "Request Dashboard",
      //   value: "requestdashboard",
      //   route: "/requestBoard",
      //   imageUrl: "https://synergy-snaps.s3.ap-south-1.amazonaws.com/public/icons/daily-activity.svg",
      //   hideMenu: false,
      // },


      // {
      //   title: "Account Live Board",
      //   value: "liveboard",
      //   route: "/liveBoard",
      //   imageUrl:
      //     "https://synergy-snaps.s3.ap-south-1.amazonaws.com/public/icons/live.png",
      //   active: false,
      //   hideMenu: false,
      // },
      // {
      //   title: "Employee Live Board",
      //   value: "employeeLiveBoard",
      //   route: "/employeeLiveBoard",
      //   imageUrl:
      //     "https://synergy-snaps.s3.ap-south-1.amazonaws.com/public/icons/account-live.png",
      //   active: false,
      //   hideMenu: false,
      // },
      // {
      //   title: "Daily Activity DashBoard",
      //   value: "dailyActivityDashBoard",
      //   route: "/dailyActivityDashBoard",
      //   imageUrl:
      //     "https://synergy-snaps.s3.ap-south-1.amazonaws.com/public/icons/daily-activity.svg",
      //   active: false,
      //   hideMenu: false,
      // },
      
      // {
      //   title: "Process Lead Dashboard",
      //   value: "processleaddashboard",
      //   route: "/processLeadDashboard",
      //   imageUrl:
      //     "https://synergy-snaps.s3.ap-south-1.amazonaws.com/public/icons/account-live.png",
      //   active: false,
      //   hideMenu: false,
      // },
      {
        title: "Exception Reports",
        value: "exceptionreports",
        route: "/exceptionBoard",
        imageUrl:
        "https://synergy-snaps.s3.ap-south-1.amazonaws.com/public/icons/exception.png",
        active: false,
        hideMenu: false,
      },
      {
        title: "Dashboard",
        value: "exceptionreports",
        route: "/dashBoard",
        imageUrl:
        "https://synergy-snaps.s3.ap-south-1.amazonaws.com/public/icons/commondashboard.png",
        active: false,
        hideMenu: false,
      }
      // {
      //   title: "Request Board",
      //   value: "Allrequest",
      //   route: "/requestBoard2",
      //   imageUrl:
      //   "https://synergy-snaps.s3.ap-south-1.amazonaws.com/public/icons/exit.png",
      //   active: false,
      //   hideMenu: false,
      // },
    ],
  }),
  async created() {
    let userDetails = localStorage.getItem("user_details");
    userDetails = userDetails ? JSON.parse(userDetails) : {};
    if (
      userDetails.roles &&
      userDetails.roles.role_type &&
      userDetails.roles.role_type <= 5
    ) {
      // Show the "Team Lead Dashboard" menu item
      this.mainMenu[6].hideMenu = false;
    }

    this.mainMenu[1].hideMenu =
      userDetails &&
        userDetails.roles &&
        userDetails.roles.role_type &&
        userDetails.roles.role_type < 7
        ? false
        : true;
    await this.getMyRole();
    await this.getPermissionObjects();
    await this.getMenu();
    this.changeRoute(window.location.pathname);
  },
  methods: {
    stopRail() {
      this.rail = !this.rail;
      this.$emit("toggle-rail");
    },
    async getMenu() {
      let $menu = [];
      let $menuOptions = await MenuService.findAll({active:1});
      this.menuOptions = $menuOptions;
      let $groups = $menuOptions.filter((obj) => {
        return obj.parent_id == 0;
      });
      $groups = lodash.orderBy($groups, ["sequence_num"], ["asc"]);
      console.log($groups, "group data");
      $groups.forEach((obj) => {
        let SubMenu = $menuOptions.filter((f) => f.parent_id == obj.id);
        let SubMenuData = [];
        SubMenu.forEach((s) => {
          // console.log(s.name);
          // console.log(this.activities);
          // console.log(this.isOkToShow(s.activities),"hello fatataaa",s);
          if (this.isOkToShow(s.activities)) {
            SubMenuData.push({
              id: s.id,
              code: s.code,
              name: s.name,
              icon: s.icon,
              route: s.route,
            });
          }
        });
        console.log(SubMenuData);
        if (SubMenuData.length > 0) {
          $menu.push({
            id: obj.id,
            code: obj.name,
            name: obj.name,
            icon: obj.icon,
            subMenu: SubMenuData,
          });
        }
      });
      this.menuData = this.menu.concat($menu);
      console.log(this.menuData,"hhhhhhhhhhhhhh333332222")
    },
    isOkToShow($activities) {
      let $allowed = false;
      let activities = $activities ? JSON.parse($activities):[]
      for (let $permission of this.permissions) {
        if (activities && activities.includes($permission.activity_id)) {
          if (
            $permission.actions.indexOf("create") >= 0 ||
            $permission.actions.indexOf("CREATE") >= 0
          ) {
            $allowed = true;
          }
        }
      }
      return $allowed;
    },
    async getPermissionObjects() {
      this.permissions = await RolePermissionService.findAll({
        active: 1,
        role_id: this.getMyRole(),
      });
    },
    getMyRole() {
      let $user = localStorage.getItem("user_details");
      $user = JSON.parse($user);
      return $user.role_id;
    },
    async getmenu2() {
      let $menu = [];
      let res = await ActivityGroupService.getAll();
      let $data = res.filter(
        (v, i, a) => a.findIndex((v2) => v2.module_groups === v.module_groups) === i
      );
      $data.forEach((obj) => {
        let SubMenu = res.filter((f) => f.module_groups == obj.module_groups);
        let SubMenuData = [];
        SubMenu.forEach((s) => {
          SubMenuData.push({
            title: s.menu_group,
            icon: s.group_icon,
            route: s.route,
            active: false,
          });
        });
        $menu.push({
          title: obj.module_groups,
          icon: obj.module_icon,
          expand: false,
          subMenu: SubMenuData,
        });
      });
      this.menuData = this.menu.concat($menu);
    },
    onMouseHover() {
      this.navDrawerPinned = true;
    },
    onMouseLeave() {
      this.navDrawerPinned = false;
    },
    checkRoute(item) {
      if (item.value == "backlog") {
        this.$store.commit("SET_ISSUE_DISPLAY", "list");
        localStorage.setItem("issueDisplay", "list");
      } else if (item.value == "board") {
        this.$store.commit("SET_ISSUE_DISPLAY", "board");
        localStorage.setItem("issueDisplay", "board");
      }
      this.$router.push(item.route);
      this.changeRoute(item.route, "above");
    },
    MenuRoute(item) {
      if (item.route) {
        this.$router.push(`${item.route}`);
        this.changeRoute(item.route, "below");
      } else {
        this.$router.push("/");
      }
    },
    changeRoute(route) {
      let url = route;

      for (let i = 0; i < this.menuData.length; i++) {
        for (let j = 0; j < this.menuData[i].subMenu.length; j++) {
          if (this.menuData[i].subMenu[j].route == url) {
            this.menuData[i].subMenu[j].active = true;
          } else {
            this.menuData[i].subMenu[j].active = false;
          }
        }
        // this.menuData[i].subMenu=[this.menuData[i].subMenu]
      }
      for (let $i = 0; $i < this.mainMenu.length; $i++) {
        if (this.mainMenu[$i].route == url) {
          this.mainMenu[$i].active = true;
        } else {
          this.mainMenu[$i].active = false;
        }
      }
    },
  },
};
</script>

<style>
.side-menu {
  background-color: #e8f3f9;
}

.menu-item {
  font-size: 12px;
  line-height: 24px;
  color: #000000;
  font-weight: 700;
}

.menu-itemL:hover {
  background-color: #1258ab;
  color: #ffffff;
}

.v-list-item__prepend .v-icon {
  color: 0000;
  font-weight: 800;
}

.v-list-item-title {
  font-size: 14px;
}

.menu-group {
  font-size: 12px;
  font-weight: 800;
  color: #333333;
  text-transform: uppercase;
}

.menu-item-left {
  margin-left: 15px;
}

.v-list-group--prepend {
  /* --parent-padding: calc(var(--indent-padding) + var(--prepend-width)); */
  margin-left: -28px !important;
}

.v-img__img--cover {
  height: 20px;
  width: 20px;
  margin-top: 6px;
  margin-left: 15px;
  -o-object-fit: cover;
  object-fit: cover;
}

.v-list-item:hover img {
  filter: brightness(0) invert(1);
}

element.style {
  left: 0px;
  z-index: 0;
  transform: translateX(0%);
  position: fixed;
  height: 100vh;
  top: 72px;
  bottom: 0px;
  width: 55px;
}

/* .select-menu {
  background-color: #d1dae0 !important;
} */

.v-navigation-drawer--temporary {
  box-shadow: none;
}

.v-navigation-drawer__content {
  flex: 0 1 auto;
  height: 93vh;
  overflow-y: auto;
}
</style>
