<template>
  <v-card width="100%" style="border-radius: 12px; overflow: hidden">
    <v-form  ref="form">
      <v-card-item>
        <v-card-title>
          Confirmation
          <v-btn
            @click="closeDialog"
            elevation="0"
            density="comfortable"
            icon="mdi-close"
            class="x-closeIcon"
            style="font-size: 20px;"
          ></v-btn>
        </v-card-title>
      </v-card-item>

      <v-card-text>
        <v-row no-gutters class="mt-3 pa-2">
          <v-col v-if="data.approveType && ['approve','reject','aprroveall'].includes(data.approveType)">
            Do you want to  <b :style="{ color: data?.approveType === 'reject' ? 'red' : 'green' }">{{ data?.approveType === 'reject' ? 'Reject' : 'Approve' }}</b> the <b>{{ type ? 'All ' : '' }}</b>Time Sheet?
          </v-col>          
          <v-col v-else>
            Please Confirm! that you are requesting to modify the time in the timesheet?
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-action class="mr-3">
        <v-row justify="end" class="dialogBottom">
          <v-btn class="mr-2 cancel" color="red" @click="closeDialog">No</v-btn>
          <v-btn class="save" @click="$emit('confirm-to-approval', data)">Yes</v-btn>
        </v-row>
      </v-card-action>
    </v-form>
  </v-card>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => ({ data: null }),
    },
  },
  data() {
    return {
      type: false,
    };
  },
  watch: {
    data: {
      handler: 'setType',
      immediate: true,
    },
  },
  methods: {
    setType() {
      this.type = this.data?.data === 0;
    },
    closeDialog() {
      this.$emit('close');
    },
  },
};
</script>
