import TimeSheetService from "./TimeSheetService"
import moment from 'moment'
import store from '../../../store/index'

const excludes = ['meeting', 'break', 'login', 'logout']
const TimerService = {
    processTime: async function (data, taskType, $start,$taskSelected,inBetweenTime,previousIssueId) {
        //data.company_id = userData.company_id
        /*if($start) {
            let $timer = localStorage.getItem('timer')
            $timer = JSON.parse($timer)
            if($timer) {
                await TimeSheetService.processTime2(data,$timer.type,false);
            }
        }*/
        if (excludes.includes(taskType)) {  
            data.customer_id = 0
            data.issue_id = 0
            data.project_id = 0
            data.customer_id = 0
            data.description = taskType
            if(taskType == 'meeting' && $taskSelected && $taskSelected.account_id){
                data.account_id = $taskSelected.account_id
            }
        }
        if($taskSelected) {
            data.task_id=$taskSelected.id
        }
        // data.resource_id = userData.id
        data.stage = 'pending'
        data.actual_hrs = 0
        //  data. end_date = moment(new Date()).tz('utc')
        //data.start_date = moment(new Date()).utcOffset("+05:30").format('YYYY/MM/DD HH:mm:ss')
        //data.entry_date = moment(new Date()).utcOffset("+05:30").format('YYYY/MM/DD HH:mm:ss')
        //let task = await TaskService.findAll({ code : value ? value: "LOGIN" })
        //data.task_id = task[0].id
        //data.description = value ? value:"LOGIN"
        //alert(localStorage.getItem('TOKEN'))
        if ($start) {
            $start = 'start'
        } else {
            $start = 'stop'
        }      
        if(!inBetweenTime){
            let $status =  store.getters.getInBetweenTime
            store.commit("SET_IN_BETWEEN_TIME", !$status);
        }
        await TimeSheetService.processTime2(data, taskType, $start);
        if(previousIssueId && previousIssueId>0){
            return true
        }
        else if($start == 'stop'  && taskType != process.env.VUE_APP_IBT_CODE){
            TimerService.startInBetweenTime()
        }
    },   
    startInBetweenTime : async function(){
        let $data
        let $code = process.env.VUE_APP_IBT_CODE
        $data = { description : $code}
        let $timer = {
                "type": process.env.VUE_APP_IBT_CODE,
                "start":  JSON.stringify(moment(new Date())),
                "meetingName": process.env.VUE_APP_IBT_CODE
            }
        
        localStorage.setItem("timer", JSON.stringify($timer))
        // alert("store")
        let $status =  store.getters.getInBetweenTime
        store.commit("SET_IN_BETWEEN_TIME", !$status);
        await TimerService.processTime($data,$code,true,null,true)
    }
}
export default TimerService;