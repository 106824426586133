<template>
  <v-card elevation="0" style="border-radius: 10px">
    <v-form @submit.prevent="save" ref="form">
      <v-card-item>
        <v-card-title>
          {{
            this.type === "action" && !showReadOnly
              ? "Edit"
              : showReadOnly
              ? "View"
              : "Add"
          }}
          Hold
          <v-btn
            style="float: right"
            @click="close()"
            elevation="0"
            density="comfortable"
            icon="mdi-close"
          ></v-btn>
        </v-card-title>
      </v-card-item>

      <v-card-text>
        <v-row class="py-0 my-0">
          <v-col cols="12" class="px-0 py-0" style="display: grid">
            <v-radio-group
              :disabled="showReadOnly"
              v-model="formData.followup_type"
              inline
              @change="getUsers(formData.followup_type)"
              v-if="true"
            >
              <v-radio color="primary" label="Internal" :value="1"></v-radio>
              <v-radio color="error" label="External" :value="0"></v-radio>
            </v-radio-group>
          </v-col>
          <!-- <v-col cols="6" class="py-0 my-0 dropdown">
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
              >Hold [E]
              <span style="color: #cf0707; padding-left: 2px; font-weight: 700"
                >*</span
              ></v-label
            >
            <EnumAutoCompleteVue
              class="dropdown"
              density="compact"
              v-model="formData.hold"
              :selected="formData.hold"
              :domain="'HOLD'"
              placeholder="Hold"
              :showReadOnly="showReadOnly"
              @changeEnumData="holdChange($event)"
              :rules="holdRule"
            />
          </v-col> -->
          <v-col cols="6" class="py-0 my-0 dropdown">
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
            >
              Pending With
              <span style="color: #cf0707; padding-left: 2px; font-weight: 700"
                >*</span
              ></v-label
            >
            <AutoComplete
              :disabled="showReadOnly"
              class="dropdown"
              :items="users"
              :item-title="'name'"
              :item-value="'id'"
              density="compact"
              v-model="formData.user"
              placeholder="User"
              :rules="userRule"
              v-if="true"
            />
          </v-col>
          <!-- <v-col cols="5" v-if="!switchMe && !showReadOnly" class="py-0 my-0">
            <v-switch v-model="switchMe" :disabled="showReadOnly" color="primary">
              <template v-slot:label> Followup Date Required ? </template>
            </v-switch>
          </v-col> -->

          <v-col cols="5" class="py-0 my-0">
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
              >FollowUp Date
              <span
                v-if="true"
                style="color: #cf0707; padding-left: 2px; font-weight: 600"
                >*</span
              ></v-label
            >
            <TextFieldComponent
              :showReadOnly="showReadOnly"
              density="compact"
              placeholder="FollowUp date"
              :rules="dateRule"
              v-model="formData.followup_date"
              type="date"
            />
          </v-col>

          <v-col cols="12">
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
              >Description</v-label
            >
            <DescriptionVue
              class="pb-0"
              editorStyle="height: 120px"
              :data="formData.description"
              :showReadOnly="showReadOnly"
              ref="descriptionVue"
              @textData="changeDescription($event)"
            ></DescriptionVue>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-action class="mr-3">
        <v-row justify="end" class="dialogBottom">
          <v-btn style="--v-btn-height: 35px" class="mr-2 cancel" @click="close()"
            >Cancel</v-btn
          >
          <v-btn
            style="--v-btn-height: 35px"
            :loading="saveClicked"
            class="save"
            type="submit"
            >Save</v-btn
          >
        </v-row>
      </v-card-action>
    </v-form>
  </v-card>
</template>

<script>
// import TextArea from "@/components/ui/TextArea.vue";
import AutoComplete from "@/components/ui/AutoComplete.vue";
import IssueService from "@/api/service/Issue/IssueService";
import UserService from "@/api/service/UserService";
import ForumService from "@/api/service/Forum/ForumService";
// import EnumAutoCompleteVue from "@/components/others/EnumAutoComplete.vue";
import TextFieldComponent from "@/components/TextFieldComponent.vue";
import DescriptionVue from "@/components/ui/Description.vue";
import ContactService from "@/api/service/Contact/ContactService";
import moment from "moment";

export default {
  components: {
    // TextArea,
    // EnumAutoCompleteVue,
    DescriptionVue,
    TextFieldComponent,
    AutoComplete,
  },
  props: ["issueId", "showReadOnly", "type"],
  data() {
    return {
      saveClicked: false,
      switchMe: false,
      formData: {
        // hold: null,
        description: null,
        user: null,
        followup_type: null,
      },
      users: [],
      holdRule: [(value) => !!value || "Hold is Required"],
      userRule: [(value) => !!value || "User is Required"],
      dateRule:[(value) => !!value || "FollowUp Date is Required"],
    };
  },
  created() {
    this.getUsers();
    if (this.issueId && this.type && this.type == "action") {
      this.getById(this.issueId);
    }
  },
  methods: {
    async getById(id) {
      let res = await IssueService.getStageData({
        issueId: id,
        type: "hold",
      });
      if (res && res.length > 0) {
        // if (res[0].followup_date != null) {
        //   this.switchMe = true;
        // }
        this.getUsers(res[0].followup_type);
        // this.formData.hold = res[0].hold_type ? res[0].hold_type : null;
        this.formData.description =
          res[0].forums && res[0].forums.description ? res[0].forums.description : null;
        this.formData.f_id =
          res[0].forums && res[0].forums.f_id ? res[0].forums.f_id : null;
        this.formData.user = parseInt(res[0].followup_user);
        this.formData.followup_date = res[0].followup_date
          ? moment(res[0].followup_date).format("YYYY-MM-DD")
          : null;
        this.formData.followup_type = res[0].followup_type == "internal" ? 1 : 0;
      }
    },
    close() {
      this.$emit("closeDialog");
    },
    holdChange($event) {
      this.formData.hold = $event;
    },
    changeDescription($event) {
      this.formData.description = $event;
    },
    async getUsers(type) {
      this.formData.user = null;
      if (type == 1 || type == "internal") {
        let res = await UserService.findAll();
        this.users = res && res.length > 0 ? res : [];
        console.log(this.users, "users");
      } else if (type == 0 || type == "external") {
        let res = await ContactService.allContacts();
        this.users =
          res && res.length > 0 ? res.filter((contact) => contact.active === 1) : [];
      } else {
        this.users = [];
      }
    },
    // clearFollowUpDate() {
    //   this.switchMe = false;
    //   this.formData.followup_date = null;
    // },

    async save() {
      let validation = await this.$refs.form.validate();
      if (!validation.valid) {
        console.log(validation.valid);
      } else {
        if (this.issueId) {
          if (!this.formData.description) {
            this.$refs.descriptionVue.showErrorMessage("Description is Required");
            return;
          }
          this.saveClicked = true;
          let $issueStatus = this.$store.getters.getIssueStatus;				
          await IssueService.update({
            id: this.issueId,
            stage: "hold",
            followup_date: this.formData.followup_date,
            // hold_type: this.formData && this.formData.hold ? this.formData.hold : "",
            followup_user: this.formData && this.formData.user ? this.formData.user : "",

            followup_type:
              this.formData.followup_type && this.formData.followup_type == 1
                ? "internal"
                : "external",
          });
          if($issueStatus && $issueStatus ==1){
						this.$store.commit("SET_ISSUE_STATUS",2);
					}
          let $description =
            this.formData && this.formData.description ? this.formData.description : "";
          console.log($description, "kya aarha hai");
          let data = {
            key_type: "issue",
            key_id: this.issueId,
            action: "hold",
            description: $description,
          };
          await ForumService.create(data);
          this.$emit("saveDialog");
          this.close();

          this.formData = {};
        }
      }
    },
  },
};
</script>

<style></style>
