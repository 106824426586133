<template>
  <SideMenu  />
  <TopMenu  />
  <div style="margin-top: 100px;">
    <RouterView></RouterView>
    </div>
</template>
<script>
import SideMenu from "@/menus/SideMenu.vue";
import TopMenu from "@/menus/TopMenu.vue";
export default {
  components: {
    TopMenu,
    SideMenu,
  },
}
</script>