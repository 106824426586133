<template>
  <v-card width="100%" style="border-radius: 12px; overflow: hidden">
    <v-form @submit.prevent="save" ref="form">
      <v-card-item>
        <v-card-title>Update the Coreforce email id
          <v-btn @click="setFalse()" elevation="0" density="comfortable" icon="mdi-close" class="x-closeIcon"></v-btn>
        </v-card-title>
      </v-card-item>

      <v-card-text>
        <v-row no-gutters class="mt-3 pa-2">
          <v-col cols="12" class="py-0">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Email id
              <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
            <TextFieldComponent density="compact" placeholder="Email id" v-model="formData.email"
              :rules="emailRules" @input="anychange()" />
            <v-error v-if="showError">Updated Mail id does not exist in Synergy - Please provide user Access and try again
            </v-error>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-action class="mr-3">
        <v-row justify="end" class="dialogBottom" v-if="!showReadOnly">
          <v-btn class="mr-2 cancel" @click="dialog = setFalse()">Cancel</v-btn>
          <v-btn class="save" :loading="saveClicked" @click="save">On Board</v-btn>
        </v-row>
      </v-card-action>
    </v-form>
  </v-card>
</template>
<script>
import TextFieldComponent from '@/components/TextFieldComponent.vue';
import UserService from '@/api/service/User/UserService';
export default {
  props: ["data"],
  components:{
    TextFieldComponent
  },
  data() {
    return {
      submitType:0,
      formData: {},
      showError:false,
      saveClicked:false,
      emailRules: [
        (value) => !!value || "E-mail is required.",
        (value) => {
          if (/.+@.+\..+/.test(value)) {
            this.isValidEmail = true;
            return true;
          }
          this.isValidEmail = false;
          return "E-mail must be valid.";
        },
      ],
    }
  },
  methods: {
    setFalse() {
      this.$emit('close')
    },
    anychange() {
      this.showError = false;
    },
    async save(){
      if(!this.formData || !this.formData.email || this.formData.email.length==0){
        console.log("Error")
      }
      else{
        this.saveClicked = true
        try{
          
          let res = await UserService.findAll(this.formData)
          console.log(res,"user response data")
          if( res && res.length > 0 ){
            this.showError = false
            console.log(res,"response data")
            let $data = this.data 
            $data.personal_email = $data.email
            $data.email = this.formData.email
            this.$emit('confirmation-user', $data)
          }
          else{            
            this.saveClicked = false
            this.showError = true
          }

        }
        catch(e){
          console.log(e)
        }
      }
    }
  }
}
</script>