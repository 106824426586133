<template>
  <v-card class="pa-2">
    <template v-slot:append>
        <v-btn icon="mdi-close" variant="text" @click="$emit('closeCard')"></v-btn>
      </template>
      <template v-slot:title>
        Model Name
      </template>
    <v-card-text>
        <TextFieldComponent
      v-if="allowEdit"
      density="compact"
      placeholder="Enter name"
      :rules="nameRule"
      v-model="modelName"
      @change="modelData"
    />
    </v-card-text>
<v-card-actions>
    <v-row justify="end" class="dialogBottom" v-if="!showReadOnly">
            <v-btn class="mr-2 cancel" @click="$emit('closeCard')">Cancel</v-btn>
            <v-btn class="save" type="submit" @click="submit">Save</v-btn>
          </v-row>
</v-card-actions>

  </v-card>
</template>
<script>
import TextFieldComponent from "@/components/TextFieldComponent.vue";
export default {
  props: ["allowEdit", "nameRule", "submit"],
  components: {
    TextFieldComponent,
  },
  data() {
    return {
        modelName:''
    };
  },
  methods:{
    modelData(){
        this.$emit('modelName',this.modelName)
    }
  }
};
</script>
