<template>
  <v-card class="pa-2">
    <template v-slot:append>
        <v-btn icon="mdi-close" variant="text" @click="$emit('closeCard')"></v-btn>
      </template>
      <template v-slot:title>
        Update Story
      </template>
    <v-card-text>
      <v-textarea
                  label="Update other details"
                  row-height="25"
                  class="opacity-50"
                  rows="3"
                  variant="outlined"
                  single-line
                  v-model="textAreaData"
                  @change="modelData"
                ></v-textarea>
    </v-card-text>
<v-card-actions>
    <v-row justify="end" class="dialogBottom" v-if="!showReadOnly">
            <v-btn class="mr-2 cancel" @click="$emit('closeCard')">Cancel</v-btn>
            <v-btn class="save" type="submit" @click="submit">Save</v-btn>
          </v-row>
</v-card-actions>

  </v-card>
</template>
<script>
export default {
  props: ["allowEdit", "nameRule", "submit"],
  data() {
    return {
        textAreaData:''
    };
  },
  methods:{
    modelData(){
        this.$emit('textAreaData',this.textAreaData)
    }
  }
};
</script>
