<template>
  <v-card class="pa-2" width="100%">
    <v-form @submit.prevent="save" ref="form">
      <v-card-item>
        <v-card-title>
          <b>Associate Data Model</b>
                  <v-btn
            style="float: right"
            @click="setFalse"
            elevation="0"
            density="comfortable"
            icon="mdi-close-circle"
          ></v-btn>
        </v-card-title>
      </v-card-item>

      <v-card-text>
        <v-row no-gutters>
          <v-col cols="10" class="dropdown">
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
              >Data Model
              <span
                v-if="!showReadOnly"
                style="color: #cf0707; padding-left: 2px; font-weight: 700"
                >*</span
              >
              <span><v-tooltip activator="parent" location="bottom">Add Model</v-tooltip><v-icon size="25px" color="#3C5AAA" @click="showDialg('model')">mdi-plus-circle
              </v-icon></span> 
              </v-label
            >
            <AutoComplete
              density="compact"
              :rules="typeRule"
              v-model="formData.list_model_id"
              placeholder="Data Model"
              :items="modelData"
              item-title="name"
              item-value="id"
              :disabled="showReadOnly"
            />
          </v-col>
          <!-- <v-col cols="10" class="dropdown">
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
              >Sequence
              <span
                v-if="!showReadOnly"
                style="color: #cf0707; padding-left: 2px; font-weight: 700"
                >*</span
              ></v-label
            >
            <AutoComplete
              density="compact"
              :rules="typeRule"
              v-model="formData.sequence"
              placeholder="Sequence"
              :items="sequenceData"
              :disabled="showReadOnly"
            />
          </v-col> -->

          <!-- <v-col cols="10" class="dropdown">
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
              >Parent
              <span
                v-if="!showReadOnly"
                style="color: #cf0707; padding-left: 2px; font-weight: 700"
                >*</span
              ></v-label
            >
            <AutoComplete
              density="compact"
              :rules="typeRule"
              v-model="formData.parent_id"
              placeholder="Parent"
              :items="modelData"
              item-title="name"
              item-value="id"
              :disabled="showReadOnly"
            />
          </v-col> -->
        </v-row>
        <v-row no-gutters>
          <v-col cols="10" class="dropdown">
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
              >Form Display Type [E]
              <span
                v-if="!showReadOnly"
                style="color: #cf0707; padding-left: 2px; font-weight: 700"
                >*</span
              >
              <span><v-tooltip activator="parent" location="bottom">Add Enum</v-tooltip><v-icon size="25px" color="#3C5AAA" @click="showDialg('enum')">mdi-plus-circle
              </v-icon></span> 
              </v-label
            >
            <EnumAutoCompleteVue
              density="compact"
              :rules="displayRule"
              v-model="formData.form_display_type"
              :showReadOnly="showReadOnly"
              :selected="formData.form_display_type"
              :domain="'FORM_DISPLAY_TYPE'"
              placeholder="Form Display Type "
              @changeEnumData="changeDisplayType($event)"
            />
          </v-col>
        </v-row>
        <!-- <v-row no-gutters>
          <v-col cols="10" class="dropdown">
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
              >Relation ship with Parent [E]
              <span
                v-if="!showReadOnly"
                style="color: #cf0707; padding-left: 2px; font-weight: 700"
                >*</span
              ></v-label
            >
            <EnumAutoCompleteVue
              density="compact"
              :rules="parentRule"
              v-model="formData.parent_relation"
              :showReadOnly="showReadOnly"
              :selected="formData.parent_relation"
              :domain="'PARENT_RELATION'"
              placeholder="Relation ship with Parent "
              @changeEnumData="changeRelation($event)"
            />
          </v-col>
        </v-row> -->

        <!-- <v-row no-gutters>
          <v-label
            class="pb-1"
            style="font-size: 14px; color: #272727; font-weight: 600"
            >Key Mappings</v-label
          >
          <v-icon
            size="25px"
            class="pl-4"
            color="#3C5AAA"
            @click="addKeyMapping"
            >mdi-plus-circle</v-icon
          >
        </v-row> -->

        <!-- <v-row
          no-gutters
          v-if="formData.key_mappings && formData.key_mappings.length > 0"
        >
          <v-col cols="6">
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
              >Parent Key</v-label
            >
          </v-col>
          <v-col cols="6">
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
              >Child Key</v-label
            >
          </v-col>
        </v-row> -->

        <!-- <v-row
          no-gutters
          v-for="(item, index) in formData.key_mappings"
          :key="index"
        >
          <v-col cols="5">
            <AutoComplete
              density="compact"
              :rules="typeRule"
              v-model="item.parent_key"
              placeholder="Parent Key"
              :items="modelData"
              item-title="name"
              item-value="id"
              :disabled="showReadOnly"
            />
          </v-col>
          <v-col offset="1" cols="5">
            <AutoComplete
              density="compact"
              :rules="typeRule"
              v-model="item.child_key"
              placeholder="Child Key"
              :items="modelData"
              item-title="name"
              item-value="id"
              :disabled="showReadOnly"
            />
          </v-col>
          <v-col cols="1">
            <v-icon @click="removeKeyMapping(index)" class="mt-2 pl-3"
              >mdi-trash-can-outline</v-icon
            >
          </v-col>
        </v-row> -->
      </v-card-text>

      <v-card-actions class="mr-3">
        <v-row justify="end" class="dialogBottom" v-if="!showReadOnly">
          <v-btn class="mr-2 cancel" @click="setFalse()">Cancel</v-btn>
          <v-btn class="save" type="submit">Save</v-btn>
        </v-row>
      </v-card-actions>
    </v-form>
    <v-dialog persistent v-model="enumDialog" width="400px">
    <AddEnum
      @closeDialog="setFalse('enumDialog')"
    />
  </v-dialog>
  <v-dialog persistent v-model="listModelDialog" width="700px">
    <AddListModel @closeDialog="setFalse('listModelDialog')" />
  </v-dialog>
  </v-card>
</template>

<script>
import AutoComplete from "@/components/ui/AutoComplete.vue";
import ListModelService from "@/api/service/ListModel/ListModelService";
import AssociateDataModelService from "@/api/service/FormBuilder/AssociateDataModelService";
import AddListModel from "@/modules/settings/Technical/Model/AddListModel.vue";
import EnumAutoCompleteVue from "../../components/others/EnumAutoComplete.vue";
import AddEnum from "@/modules/settings/Others/Enum/AddEnum.vue";
import commonService from "@/common.service";

export default {
  props: ["id", "formId", "viewType","selectedModels"],
  components: {
    EnumAutoCompleteVue,
    AutoComplete,
    AddEnum,
    AddListModel
  },
  data() {
    return {
      showReadOnly: false,
      enumDialog: false,
      listModelDialog: false,
      modelData: [],
      sequenceData: [
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
      ],
      dialog: false,
      type: null,
      size: {},
      formData: {
        key_mappings: [{ parent_key: "", child_key: "" }],
      },
      canAddNewRow: false,
    };
  },
  watch: {
    id() {
      if (this.id) {
        this.getById(this.id);
      }
    },
    formId() {
      if (this.formId) {
        this.formData.form_id = this.formId;
      }
    },
  },

  created() {
    if (this.id) {
      this.getById(this.id);
    }
    this.getDataByModel();
    if (this.formId) {
      this.formData.form_id = this.formId;
    }

    if (this.viewType) {
      this.showReadOnly = this.viewType == "edit" ? false : true;
    }
  },
  methods: {
    showDialg($event) {
      if($event == 'model'){
        this.listModelDialog = true
      }else if ($event == 'enum'){
        this.enumDialog = true
      }
      // this.$router.push(`/setting/others/enums`)
    },
    async getDataByModel() {
      let $listModel = await ListModelService.findAll();

      $listModel = commonService.getObjectSliceMethod(JSON.stringify($listModel), this.selectedModels, 'id')
      this.modelData = $listModel;
    },
    prevent(e) {
      if (!/^[a-zA-Z0-9-_ \t]+$/.test(e.key) && e.key !== "Backspace") {
        e.preventDefault();
      }
    },
    changeDisplayType($value) {
      this.formData.form_display_type = $value;
    },
    changeRelation($value) {
      this.formData.parent_relation = $value;
    },
    async getById(id) {
      let res = await AssociateDataModelService.findOne(id);
      this.formData = res[0];
    },
    validateLastRow() {
      const lastRow = this.formData.key_mappings[
        this.formData.key_mappings.length - 1
      ];
      this.canAddNewRow = lastRow.parent_key && lastRow.child_key;
    },

    addKeyMapping() {
      this.validateLastRow();
      if (this.canAddNewRow) {
        this.formData.key_mappings.push({ parent_key: null, child_key: null });
        this.canAddNewRow = false;
      }
    },

    removeKeyMapping(index) {
      if (this.formData.key_mappings.length > 1) {
        this.formData.key_mappings.splice(index, 1);
        this.validateLastRow();
      }
    },

    setFalse(event) {
      if(event === 'enumDialog'){
        this.enumDialog = false
      }else if (event == 'listModelDialog'){
        this.listModelDialog = false
      }
      else{
        this.$emit("closeDialog");
      }
    },
    async save() {
      let validation = await this.$refs.form.validate();
      if (!validation.valid || this.codeError) {
        console.log("Not valid");
      } else {
        this.formData.key_mappings = this.formData.key_mappings ? JSON.stringify(this.formData.key_mappings) :'[]'
        if (this.formData.id) {
          let output = await AssociateDataModelService.save(this.formData);
          console.log(output);
          this.$store.dispatch("setSnackbar", { text: "Updated Successfully" });
          this.setFalse();
          this.dialog = false;
          this.$emit("reload", true);
          this.formData = [];
        } else {
          try {
            console.log(this.formData, "dataaaa");
            let output = await AssociateDataModelService.create(this.formData);
            console.log(output);
            this.$store.dispatch("setSnackbar", { text: "Saved Successfully" });
            this.dialog = false;
            this.setFalse();
            this.$emit("reload", true);
            this.formData = [];
          } catch (e) {
            console.log(e ,'whats the error')
            this.$store.dispatch("setSnackbar", {
              color: "error",
              text: "Something Went Wrong",
            });
          }
        }
      }
    },
  },
};
</script>

<style scoped>
.keyMapping {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.keyMapping > *:nth-child(1),
.keyMapping > *:nth-child(2) {
  flex: 0 0 44%;
}

.keyMapping > *:nth-child(3) {
  flex: 0 0 10%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.add-btn{
    font-size: 18px;
    font-weight: bold;
    color: #0c3b73;
}
.gap {
    margin: 0 10px;
}
</style>
