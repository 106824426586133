import ApiService from './apiServiceIdentity';
import securityApiService from './apiServiceSecurity'
import apiConfig from '../config/config';
const querystring = require('querystring')

var $options = {};
var UserService = {
    
    fireBaseLogin: function($data) {
        return new Promise(function(resolve, reject) {
            $options.url = "authenticate"
            $options.baseUrl=apiConfig.gatWayUrl
            $options.method = "POST"
            $options.type ='firebase'
            $options.data = $data
            ApiService.request($options)
                .then(response => {
                    resolve(response.data)
                }).catch(error => {
                    reject(error.response.data);
                })
        });
    },
    
    getReporterUserData: function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.url = "getReporterUserData"
            $options.method = "get"

            if ($data) {
                $options.url = "getReporterUserData?" + querystring.stringify($data)
                console.log($options.url)
            }
            ApiService.request($options)
            .then(response => {
                console.log('test');
                resolve(response.data.data)
            }).catch(error => {
                reject(error);
           
            })
        });


    },
    impersonate: function($data) {
        return new Promise(function(resolve, reject) {
            $options.url = "impersonate?"+querystring.stringify($data)
            $options.type='impersonate'
            $options.baseUrl=apiConfig.gateway
            $options.method = "POST"
            // $options.data = $data
            ApiService.request($options)
                .then(response => {
                    resolve(response.data.data)
                }).catch(error => {
                    reject(error);
                })
        });
    },
    authenticate: function($data) {
        return new Promise(function(resolve, reject) {
            $options.url = "authenticate?"+querystring.stringify($data)
            $options.type='authenticate'
            $options.baseUrl=securityApiService.gateway
            $options.method = "POST"
            // $options.data = $data
            securityApiService.request($options)
                .then(response => {
                    resolve(response.data)
                }).catch(error => {
                    reject(error);
                })
        });
    },

    addNewSession: function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.data = $data
            $options.url = "addNewSession"
            $options.method = "post"
            console.log($options)
            securityApiService.request($options)
                .then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                });
        })
    },
    findAll: function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.url = "users"
            $options.method = "get"

            if ($data) {
                $options.url = "users?filter=" + JSON.stringify($data)
                console.log($options.url)
            }
            ApiService.request($options)
            .then(response => {
                console.log('test');
                resolve(response.data.data)
            }).catch(error => {
                reject(error);
            })
        });

    },
    
    getTeamStatus: function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.url = "getTeamStatus"
            $options.method = "get"

            if ($data) {
                $options.url = "getTeamStatus?filter=" + JSON.stringify($data)
                console.log($options.url)
            }
            ApiService.request($options)
            .then(response => {
                console.log('test');
                resolve(response.data.data)
            }).catch(error => {
                reject(error);
            })
        });

    },

    getAll: function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.url = "getAll"
            $options.method = "get"

            if ($data) {
                $options.url = "getAll?" + querystring.stringify($data)
                console.log($options.url)
            }
            ApiService.request($options)
            .then(response => {
                console.log('test');
                resolve(response.data.data)
            }).catch(error => {
                reject(error);
            })
        });

    },

    getUserByWorkSpace: function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.url = "workSpceUser"
            $options.method = "get"

            if ($data) {
                $options.url = "workSpceUser?" + querystring.stringify($data)
                console.log($options.url)
            }
            ApiService.request($options)
            .then(response => {
                console.log('test');
                resolve(response.data.data)
            }).catch(error => {
                reject(error);
            })
        });

    },

    getNextAction: function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.url = "getNextAction"
            $options.method = "get"

            if ($data) {
                $options.url = "getNextAction?" + querystring.stringify($data)
                console.log($options.url)
            }
            ApiService.request($options)
            .then(response => {
                console.log('test');
                resolve(response.data.data)
            }).catch(error => {
                reject(error);
            })
        });

    },
    getUsersForReview: function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.url = "userReview"
            $options.method = "get"

            if ($data) {
                $options.url = "userReview?" + querystring.stringify($data)
                console.log($options.url)
            }
            ApiService.request($options)
            .then(response => {
                console.log('test');
                resolve(response.data.data)
            }).catch(error => {
                reject(error);
            })
        });

    },

    getVerifyAccount: function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.url = "verifyAccount"
            $options.method = "get"

            if ($data) {
                $options.url = "verifyAccount?" + querystring.stringify($data)
                console.log($options.url)
            }
            ApiService.request($options)
            .then(response => {
                console.log('test');
                resolve(response.data.data)
            }).catch(error => {
                reject(error);
            })
        });

    },
    
    verifyUser: function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.url = "verifyUser"
            $options.method = "get"

            if ($data) {
                $options.url = "verifyUser?" + querystring.stringify($data)
                console.log($options.url)
            }
            ApiService.request($options)
            .then(response => {
                console.log('test');
                resolve(response.data.data)
            }).catch(error => {
                reject(error);
            })
        });

    },
    findOne: function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.url = "users/" + $data.id
            console.log($options.url)
            $options.method = "get"
            if (!$data) {
                $options.data = $data
            }
            ApiService.request($options)
                .then(response => {
                    // console.log('Gpt the data');
                    resolve(response.data.data)
                }).catch(error => {
                    reject(error);
                })
        });

    },
    autoSuggestWorkTiming: function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.url = "autoSuggestWorkTiming" 
            console.log($options.url)
            $options.method = "get"
            if (!$data) {
                $options.data = $data 
            }
            ApiService.request($options)
                .then(response => {
                    // console.log('Gpt the data');
                    resolve(response.data.data)
                }).catch(error => {
                    reject(error);
                })
        });

    },
    getUserByRoleType: function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.url = "getByRoleType?" + querystring.stringify($data)
            console.log($options.url)
            $options.method = "get"
            if (!$data) {
                $options.data = $data
            }
            ApiService.request($options)
                .then(response => {
                    // console.log('Gpt the data');
                    resolve(response.data.data)
                }).catch(error => {
                    reject(error);
                })
        });

    },
    reSendEmail: function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.url = "resendemail?" + querystring.stringify($data)
            console.log($options.url)
            $options.method = "get"
            if (!$data) {
                $options.data = $data
            }
            ApiService.request($options)
                .then(response => {
                    // console.log('Gpt the data');
                    resolve(response.data.data)
                }).catch(error => {
                    reject(error);
                })
        });

    },
    update: function($data) {
        return new Promise(function(resolve, reject) {
            console.log($data,"w")
            let $options = {};
            $options.data = $data
            $options.url = "users/" + $data.id;
            $options.method = "post"
            console.log($options)
            ApiService.request($options)
                .then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                });
        })
    },
    save: function($data) {
        return new Promise(function(resolve, reject) {
            var $options = {};
            $options.data = $data
            $options.url = "users/" + $data.id;
            $options.method = "post"
            console.log($options)
            ApiService.request($options)
                .then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                });
        });
    },

    create: function($data) {
        return new Promise(function(resolve, reject) {
            var $options = {};
            $options.data = $data
            $options.url = "users";
            $options.method = "post"
            console.log($options)
            ApiService.request($options)
                .then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                });
        });
    },
    findSessions: function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.url = "sessions"
            $options.method = "get"

            if ($data) {
                $options.url = "sessions?" + querystring.stringify($data)
                console.log($options.url)
            }
            ApiService.request($options)
            .then(response => {
                console.log('test');
                resolve(response.data.data)
            }).catch(error => {
                reject(error);
            })
        });

    },
    clearSessions: function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.url = "clearSessions"
            $options.method = "post"

            if ($data) {
                $options.url = "clearSessions?" + querystring.stringify($data)
                console.log($options.url)
            }
            ApiService.request($options)
            .then(response => {
                console.log('test');
                resolve(response.data.data)
            }).catch(error => {
                reject(error);
            })
        });

    },
    getActiveSessions: function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.url = "getActiveSessions"
            $options.method = "get"

            if ($data) {
                $options.url = "getActiveSessions?" + querystring.stringify($data)
                console.log($options.url)
            }
            ApiService.request($options)
            .then(response => {
                console.log('test');
                resolve(response.data.data)
            }).catch(error => {
                reject(error);
            })
        });

    },
    updateSessions: function($data) {
        return new Promise(function(resolve, reject) {
            var $options = {};
            $options.data = $data
            $options.url = "sessions/" + $data.sessionId;
            $options.method = "post"
            console.log($options)
            ApiService.request($options)
                .then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                });
        });
    },

    createSessions: function($data) {
        return new Promise(function(resolve, reject) {
            var $options = {};
            $options.data = $data
            $options.url = "sessions";
            $options.method = "post"
            console.log($options)
            ApiService.request($options)
                .then(response => {
                    resolve(response.data.data)
                }).catch(error => {
                    reject(error)
                });
        });
    },
};
export default UserService;