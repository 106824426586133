<template>
  <v-card flat height="100vh" class="pl-2" style="overflow-y: auto;">
    <h5>Answer Data:</h5>
      <div
      class="wrapper-form pa-7 my-3"
      v-if="
        formBuilderData &&
          formBuilderData.sections &&
          formBuilderData.sections.length > 0
      "
    >
      <FormBuilder
        :key="dynamicFormKey"
        :formBuilder="formBuilderData"
        :data="freeFormData"
      />
    </div>

    <AddDetails
      v-if="
        listBuilderData &&
          listBuilderData.sections &&
          listBuilderData.sections.length > 0
      "
      :formBuilder="listBuilderData"
      :data="detailsData"
      :showAdd="allowEdit"
      :key="dynamicFormKey"
      :selectedPreview="selectedPreview"
    />
  </v-card>
</template>

<script>
import { FormBuilder } from "@tiburadev/vue-comp";
import AddDetails from "@/modules/FormBuilder/AddDetails.vue";
import AssociateDataModelService from "@/api/service/FormBuilder/AssociateDataModelService";
import ListModelDetailService from "@/api/service/ListModelDetail/ListModelDetailService";
export default {
  props:["formSample"],
  components: {
    AddDetails,
    FormBuilder,
  },
  data() {
    return {
      dynamicFormKey: 0,
      allowEdit: false,
      selectedPreview: null,
      freeFormData: {},
      detailsData: {},
      listBuilderData: {
        title: "Details",
        sections: [],
      },
      formBuilderData: {
        title: "Details",
        width: "",
        closeIcon: false,
        height2: "500",
        withContainer: false,
        layout: "",
        columns: 12,
        sections: [],
      },
    };
  },
  created() {
    this.getDatabySample();

  },

  watch: {
    freeFormData(newVal) {
      if (newVal) {
        this.dynamicFormKey++;
      }
    },
  },

  methods: {
    selectPreview(data) {
    this.selectedPreview = data;
  },
    async getDatabySample() {

      this.formData = this.formSample;

      let sampleData = this.formData.sample_data;
      sampleData = JSON.parse(sampleData);
      this.freeFormData = sampleData.header;
      this.detailsData = sampleData.detail;
      console.log(this.formData.form_id,'form id');
      this.getAssociateDataModel(this.formData.form_id)
      this.selectPreview(this.formData.form_id)
    },
    async getAssociateDataModel(formID) {
      if (!formID) return;

      let $res = await AssociateDataModelService.findAll({
        form_id: formID,
      });
console.log($res,'response data')
      let res = await Promise.all(
        $res.map(async (element) => {
          element.list_model_details = await ListModelDetailService.findListField(
            { model_id: element.list_model_id }
          );
          return element;
        })
      );
      if (!res || res.length === 0) return;

      this.selectedModels = res.map((item) => item.list_model_id);

      const getFormFields = async (items) => {
        return Promise.all(
          items
            .filter((item) => item.field_type !== null)
            .map(async (item) => ({
              multiple: item.multi_select,
              value: item.value,
              text: item.text,
              column_name: item.column_name,
              label: item.label,
              required: item.column_required === 1,
              duplicate: true,
              options: item.options,
              field_type: item.field_type,
              maxLength: item.maxLength || null,
              minLength: item.minLength || null,
              cols: item.width,
              disabled: true,
            }))
        );
      };

      const freeformItems = res.filter(
        (el) => el.form_display_type === "freeform"
      );
      const masterDetailItems = res.filter(
        (el) => el.form_display_type === "masterdetail"
      );
      this.formBuilderData.sections = [];
      this.listBuilderData.sections = [];
      for (let freeform of freeformItems) {
        const formFields = await getFormFields(
          freeform.list_model_details || []
        );
        this.formBuilderData.sections.push({ columns: formFields });
      }

      let i = 0;
      for (let listForm of masterDetailItems) {
        const detailsField = await getFormFields(
          listForm.list_model_details || []
        );
        this.listBuilderData.sections.push({
          title: `masterdetail ${i++}`,
          columns: detailsField,
        });
        this.dynamicFormKey++;
      }
    },

  },
};
</script>

<style scoped>
.addSample {
  font-size: 14px;
  font-weight: 500;
  color: #1258ab;
  border: 2px solid #1258ab;
  border-radius: 6px;
  padding: 8px 12px;
  margin-left: 1rem;
  height: fit-content;
  width: fit-content;
}
</style>
