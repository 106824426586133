<template>
  <v-card-item style="position: fixed; background-color: #fff; z-index: 2">
    <v-card-title style="font-size: 18px; font-weight: 800" class="pt-1">
      <v-card-subtitle class="pb-2">
        <v-icon class="pb-1" @click="goback"
          >mdi-reply-circle
          <v-tooltip activator="parent" location="start"
            >Go back to parent task</v-tooltip
          >
        </v-icon>
        <span
          style="text-transform: capitalize"
          v-if="issueData && issueData.account_name"
          >{{ issueData.account_name }} /</span
        >
        {{ issueData.reference_num }}
        <!-- <small>Id# {{ issueData.id }}</small> -->
        <v-chip
          size="x-small"
          class="bg-yellow"
          v-if="issueData && issueData.qa_required"
        >
          QA Required
        </v-chip>
        &nbsp;
        <v-btn
          desity="compact"
          size="x-small"
          @click="openRecurringDialog('edit')"
          v-if="recurringTasks.length > 0"
          label
          variant="outlined"
          class="pink-bg"
        >
          Edit Recurring
        </v-btn>

        <v-icon
          @click="$emit('closedrawer')"
          size="medium"
          class="mdi mdi-close-circle float-right"
        >
        </v-icon>
      </v-card-subtitle>
      <v-row no-gutters dense>
        <v-col cols="8">
          <v-row no-gutters dense>
            <v-col
              cols="12"
              dense
              style="line-height: 18px; display: flex; align-items: baseline"
            >
              <div
                style="max-width: 73%; text-wrap: wrap; line-height: 1"
                :title="issueData.name"
                class="line-clamp"
              >
                {{ issueData.name }}
              </div>
              <v-icon
                @click="copyTextToClipboard"
                class="pi pi-copy"
                style="font-size: 18px; position: relative; bottom: 2px"
              >
              </v-icon>
              <v-icon
                v-if="exceptStage && showEdit"
                @click="$emit('edit')"
                size="18px"
                class="pl-2 mdi mdi-pencil"
              >
              </v-icon>
              <ShowMailDetails
                v-if="issueData.mail_details"
                activator="#menu-activator"
                :issueData="issueData"
              />

              <v-switch v-if="showNextAction"
                color="primary"
                class="small-switch"
                label="All Users"
                v-model="allUser"
                @update:modelValue="changeUserData()"
              ></v-switch>
            </v-col>
            <v-col cols="12" dense>
              <v-label class="text-label" v-if="issueData && issueData.created">
                <v-icon size="18px" class="pl-2 mdi mdi-folder-plus-outline icon-label">
                </v-icon>
                &nbsp;
                {{
                  issueData &&
                  issueData.created &&
                  moment(issueData.created).format("MM/DD/YYYY hh:mm A") != "Invalid date"
                    ? `${getDateFormate(issueData.created)} ${moment(issueData.created).format("hh:mm A")}` 
                    : ""
                }}
              </v-label>
              <v-label class="text-label" v-if="issueData && issueData.end_date">
                <v-icon size="18px" class="pl-2 mdi mdi-alarm-plus icon-label"> </v-icon>
                &nbsp;
                {{
                  issueData &&
                  issueData.end_date &&
                  moment(issueData.end_date).format("MM/DD/YYYY hh:mm A") !=
                    "Invalid date"
                    ? `${getDateFormate(issueData.end_date)} ${moment(issueData.end_date).format("hh:mm A")}` 
                    : ""
                }}
              </v-label>
              <!-- <v-label class="pl-1 text-label" v-if="issueData && issueData.mail_date">
                                <v-icon size="18px" class=" pl-2 mdi mdi-email-outline icon-label">
                                </v-icon>
                                &nbsp;
                                {{
                                    issueData && issueData.mail_date && moment((issueData.mail_date)).format(
                                        "MM/DD/YYYY hh:mm A") != 'Invalid date'
                                    ? moment((issueData.mail_date)).format(
                                        "MM/DD/YYYY hh:mm A"
                                    )
                                    : ""
                                }}
                            </v-label> -->
              <v-label class="pl-1 text-label title-case">
                <v-icon size="18px" class="pl-2 mdi mdi-account-details icon-label">
                </v-icon>
                &nbsp;
                {{ issueData.submitted_name }}
              </v-label>
              <v-label :style="{ 'display': showNextAction?'unset':'none' }" class="pl-1 title-case text-label">
                <v-icon
                  id="nextaction"
                  size="18px"
                  class="pl-2 mdi mdi-account-check-outline icon-label"
                >
                </v-icon>
                &nbsp;
                {{ issueData.next_action_name }}
              </v-label>
            </v-col>
            <v-col cols="12" style="display: contents">
              <v-chip
                label
                size="small"
                variant="outlined"
                class="pink-bg ma-1 ml-0 title-case"
              >
                {{ issueData && issueData.category_name && issueData.category_name }}
              </v-chip>

              <v-chip
                label
                size="small"
                variant="outlined"
                class="teal-bg ma-1 ml-0 title-case"
              >
                {{
                  issueData && issueData.sub_category_name && issueData.sub_category_name
                }}
              </v-chip>
              <v-chip
                label
                size="small"
                variant="outlined"
                class="purple-bg ma-1 ml-0 title-case"
                id="issue-type-menu-activator"
                @click="toggleMenu($event, 'issueType')"
              >
                <!-- {{ getIssueType }} -->
                {{ issueData && issueData.issue_type_name && issueData.issue_type_name }}
                <v-icon v-if="exceptStage && showEdit">mdi-arrow-down-drop-circle</v-icon>
              </v-chip>
              <v-chip
                label
                variant="outlined"
                class="gold-bg ma-1 ml-0 title-case"
                size="small"
                v-if="issueData && issueData.task_name"
              >
                {{ issueData && issueData.task_name && issueData.task_name }}
              </v-chip>

              <v-chip
                id="priority-menu-activator"
                class="ma-1 ml-0"
                variant="outlined"
                @click="toggleMenu($event, 'priority')"
                label
                :style="{
                  backgroundColor: getBgColors(issueData && issueData.priority),
                }"
                size="small"
              >
                {{
                  issueData && issueData.priority
                    ? issueData.priority.charAt(0).toUpperCase() +
                      issueData.priority.slice(1)
                    : ""
                }}
                <v-icon v-if="issueData && issueData.stage != 'closed' && showEdit"
                  >mdi-arrow-down-drop-circle</v-icon
                >
              </v-chip>

              <v-chip
                variant="outlined"
                id="stage-menu-activator"
                @click="toggleMenu($event, 'stage')"
                label
                class="tags purple-bg ma-1 ml-0 title-case"
                size="small"
              >
                {{ getStage(issueData.stage) }}
                <!-- <span v-if="issueData.stage == 'followup'">
                  {{ " - " + issueData.no_of_followups }}
                </span> -->
                <v-icon v-if="showEdit">mdi-arrow-down-drop-circle</v-icon>
              </v-chip>

              <!-- <v-chip v-if="issueData && issueData.clarification_type" variant="outlined" label
                                @click="$emit('openClarification')" class="tags purple-bg mx-1 title-case" size="small">
                                Details
                            </v-chip> -->
            </v-col>
          </v-row>
        </v-col>

        <v-col
          cols="4"
          class="pl-1"
          style="display: flex; flex-direction: column; justify-content: space-between"
        >
          <div
            class="float-right"
            style="display: flex; justify-content: end; margin-right: 12px"
          >
            <v-btn
              id="view-menu-activators"
              rounded
              size="small"
              variant="outlined"
              class="btn-info mr-1"
            >
              <template v-slot:prepend>
                <v-icon class="mdi mdi-eye"></v-icon>
              </template>
              {{ totalViews.length }}
            </v-btn>
            <v-btn
              id="share-menu-activators"
              rounded
              label
              size="small"
              variant="outlined"
              class="btn-info mr-1"
            >
              <template v-slot:prepend>
                <v-icon class="mdi mdi-share"></v-icon>
              </template>
              {{ totalShares.length }}
            </v-btn>
            <v-btn
              id="follow-menu-activators"
              rounded
              label
              size="small"
              variant="outlined"
              class="btn-info mr-1"
            >
              <template v-slot:prepend>
                <v-icon class="mdi mdi-flash-outline"></v-icon>
              </template>
              {{ totalFollowers.length }}
            </v-btn>
            <v-btn
              @click="toggleFollowing"
              rounded
              label
              size="small"
              variant="outlined"
              class="btn-info mr-1"
            >
              {{ following ? "Un Follow" : "Follow" }}
            </v-btn>
            <v-btn
              @click="checkRecurring"
              id="extended-menu-activators"
              icon
              label
              size="24px"
              variant="flat"
            >
              <v-icon size="small" class="mdi mdi-dots-vertical" />
            </v-btn>
          </div>
          <div
            class="playContainer"
            v-if="
              exceptStage &&
              showTimer && !isMobile &&
              issueData.stage != 'qapending' &&
              issueData.next_action == this.userData.id
            "
          >
            <span
              class="text-left"
              style="font-size: 14px; font-weight: 600; opacity: 0.8; text-align: left"
            >
              {{
                issueData.stage.toLowerCase() == "inprogress" ||
                issueData.stage.toLowerCase() == "in progress"
                  ? "Stop when you are done"
                  : "Start recording time"
              }}</span
            >

            <button
              v-if="
                exceptStage &&
                showTimer &&
                issueData.stage != 'qapending' &&
                issueData.next_action == this.userData.id
              "
              rounded
              label
              size="small"
              variant="outlined"
              class="float-right btn-info mr-1"
            >
              <TimerCount
                :showHomeCard="false"
                taskType="issue"
                :keyId="issueData.id"
                :showPlay="true"
                :hideCard="false"
                :startStopWatch="startTimer"
                :stopStopWatch="stopTimer"
                :color="'rgb(18, 88, 171)'"
                @update-issue-status="updateIssueStatuDialog = true"
              />
            </button>
          </div>
        </v-col>
      </v-row>
    </v-card-title>

    <PrimeMenu
      :options="priorities"
      @optionselected="
        (data) => {
          updateData(data, 'priority');
        }
      "
      ref="priorityMenu"
    />
    <PrimeMenu
      :options="stages"
      @optionselected="
        (data) => {
          updateData(data, 'stage');
        }
      "
      :menuType="'stage'"
      ref="childStage"
    />
    <PrimeMenu
      :options="issueTypes"
      :menuType="'issueType'"
      @optionselected="
        (data, value2) => {
          updateData(data, 'issue_type_id', value2);
        }
      "
      ref="childIssueType"
    />
    <MenuOptions
      activator="#view-menu-activators"
      :id="issueId"
      :options="totalViews"
      :showAvatar="true"
    />
    <MenuOptions
      activator="#follow-menu-activators"
      :options="totalFollowers"
      :type="'follower'"
      :showAvatar="true"
    />
    <MenuOptions
      activator="#shared-list-menu-activators"
      :options="totalShares"
      :showAvatar="true"
    />
    <MenuOptions
      activator="#extended-menu-activators"
      :options="menuOptions"
      :showAvatar="false"
      @menu-selected="menuOptionSelected"
    />

    <NextAction
      v-if="exceptStage && showEdit"
      activator="#nextaction"
      :options="workSpaceUsers"
      @menuSelected="nextAction"
      :showAvatar="true"
    />

    <UserShare
      activator="#share-menu-activators"
      :options="allUsers"
      @menuSelected="shareIssue"
      :showAvatar="true"
    />
    <v-dialog v-model="recurringDialog" width="1200px" persistent>
      <CreateJob
        style="overflow: auto"
        ref="createjob"
        :viewType="viewType"
        :jobId="getJobId"
        :issueData="issueData"
        @reload="hasRecurringTask"
        endPoint="recurring"
        :params="jobParams"
        @closeDialog="cancel()"
      ></CreateJob>
    </v-dialog>
    <v-dialog v-model="adminDialog" width="650px" persistent>
      <AdminAccess
        style="overflow: auto"
        :stageData="stages"
        @closeDialog="cancel()"
        @update-stage="updateStage"
      ></AdminAccess>
    </v-dialog>
    
    <v-dialog v-model="updateIssueStatuDialog" width="650px" persistent>
      <UpdateIssueStatus
        style="overflow: auto"
        :stageData="stages"
        @closeDialog="cancel()"
        @update-stage="updateStage"
      ></UpdateIssueStatus>
    </v-dialog>
  </v-card-item>
  <v-dialog v-model="dialogVisible" max-width="400">
    <v-card>
      <v-card-title class="headline">
        <v-icon color="warning">mdi-alert-outline</v-icon>
        Warning
      </v-card-title>
      <v-card-text>Issue is already in progress</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <!-- Added spacer to push the button to the end -->
        <v-btn @click="closeDialog" color="primary">OK</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import JobsService from "@/api/service/Jobs/JobsService.js";
import CreateJob from "../../modules/Jobs/Create.vue";
import ShowMailDetails from "./ShowMailDetails.vue";
import NextAction from "./NextAction.vue";
import UserShare from "./NextAction.vue";
import TimerCount from "@/menus/Timer.vue";
import MenuOptions from "../../menus/MenuOptions.vue";
import PrimeMenu from "./PrimeMenu.vue";
import IssueTypeService from "@/api/service/IssueType/IssueTypeService";
import StageService from "@/api/service/Stage/StageService";
import EnumService from "@/api/service/Enum/EnumService";
import UserService from "@/api/service/User/UserService";
import UserService2 from "@/api/service/UserService";
import IssueService from "@/api/service/Issue/IssueService";
import AdminAccess from "./AdminAccess.vue";
import UpdateIssueStatus from "./UpdateIssueStatus.vue";
import moment from "moment";
import commonService from "@/common.service";

export default {
  props: [
    "issueData",
    "exceptStage",
    "showEdit",
    "totalViews",
    "totalFollowers",
    "totalShares",
    "showNextAction"
  ],
  components: {
    PrimeMenu,
    CreateJob,
    UserShare,
    NextAction,
    MenuOptions,
    TimerCount,
    ShowMailDetails,
    AdminAccess,
    UpdateIssueStatus
  },
  data() {
    return {
      recurringTasks: [],
      jobParams: {
        issue_id: this.issueData.id,
      },
      recurringDialog: false,
      userData: {},
      allUser: false,
      workSpaceUsers: [],
      allUsers: [],
      menuOptions: [],
      showTimer: false,
      following: false,
      isMobile:true,
      priorities: [],
      stages: [],
      issueTypes: [],
      totalViews2: [],
      backupStage: [],
      localData: {},
      adminDialog: false,
      viewType: false,
      dialogVisible: false,
      timeData:null,
      updateIssueStatuDialog:false
    };
  },
  watch: {
    issueData() {
      //this.checkBrowser();
      console.log(this.localData, "local");
      let issueInStore = localStorage.getItem("timer");
      this.hasRecurringTask();
      issueInStore = issueInStore ? JSON.parse(issueInStore) : {};
      if(issueInStore && issueInStore.type == process.env.VUE_APP_IBT_CODE){
          this.showTimer = true           
      }
      else{
        this.timeData =issueInStore
            let runningIssue =
            issueInStore && issueInStore.issueId ? issueInStore.issueId : null;
            if (runningIssue == this.issueData.id) {
            this.showTimer = true;
            } 
            else if((issueInStore && issueInStore.type )&& (issueInStore.type.toLowerCase()=='break'||issueInStore.type.toLowerCase()=='meeting')){
            this.showTimer =false
            }
            else if (runningIssue == null) {
            this.showTimer = true;
            } else {        

            this.showTimer = false;
            }
      }
     
      this.stages = this.backupStage.filter((stg) => {
        if (this.issueData && this.issueData.parent_issue_id) {
          // return stg.code !== "qapending" && stg.code !== "qacompleted" && stg.code !== "inprogress" && stg.code!=='completed';
          return (
            stg.code === "open" ||
            stg.code === "assigned" ||
            stg.code === "pending" ||
            stg.code === "closed" ||
            stg.code === "cancel"
          );
        } else {
          return (
            stg.code !== "qapending" &&
            stg.code !== "qacompleted" &&
            stg.code !== "inprogress"
          );
        }
      });
    },

  },
  async created() {
    let $userDetails = localStorage.getItem("user_details");
    this.userData = $userDetails ? JSON.parse($userDetails) : {};
    this.issueTypes = await IssueTypeService.findAll();
    this.stages = await StageService.findAll({active:1});
    this.backupStage = JSON.parse(JSON.stringify(this.stages));
    let issueInStore = localStorage.getItem("timer");
      this.hasRecurringTask();
      issueInStore = issueInStore ? JSON.parse(issueInStore) : {};
      if(issueInStore && issueInStore.type == process.env.VUE_APP_IBT_CODE){
          this.showTimer = true           
      }
      else{
            this.timeData =issueInStore
          let runningIssue =
            issueInStore && issueInStore.issueId ? issueInStore.issueId : null;
          if (runningIssue == this.issueData.id) {
            this.showTimer = true;
          }       
          else if((issueInStore && issueInStore.type )&& (issueInStore.type.toLowerCase()=='break'||issueInStore.type.toLowerCase()=='meeting')){
            this.showTimer =false
          }
          else if (runningIssue == null) {
            this.showTimer = true;
          } else {  

            this.showTimer = false;
          }
      }
      
    this.stages = this.backupStage.filter((stg) => {
      if (this.issueData && this.issueData.parent_issue_id) {
        // return stg.code !== "qapending" && stg.code !== "qacompleted" && stg.code !== "inprogress" && stg.code!=='completed';
        return (
          stg.code === "open" ||
          stg.code === "assigned" ||
          stg.code === "pending" ||
          stg.code === "closed" ||
          stg.code === "cancel"
        );
      } else {
        return (
          stg.code !== "qapending" &&
          stg.code !== "qacompleted" &&
          stg.code !== "inprogress"
        );
      }
    });

    this.isFollowing();
    this.getUsers();
    this.getAllUsers();
    this.hasRecurringTask();
    this.priorities = await EnumService.findAllDomain({ domain: "PRIORITY" });
    //this.getTotalViews()
  },
  
  mounted() {
    this.checkDevice();
    window.addEventListener('resize', this.checkDevice);
  },
  computed: {  
    getJobId() {
      if (this.recurringTasks && this.recurringTasks.length > 0) {
        return this.recurringTasks[0].id;
      }
      return null;
    },
  },
  methods: {
    moment,
    getDateFormate(date,type){
      return commonService.getformteTheDate(date,type)
    },
    checkDevice() {
      this.isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
      // alert(this.isMobile)
      // alert(navigator.userAgent)
    },
    copyTextToClipboard() {
      if (navigator.clipboard) {
        navigator.clipboard
          .writeText(this.issueData.name)
          .then(() => {
            console.log("Text successfully copied to clipboard");
          })
          .catch((err) => {
            console.error("Unable to copy text: ", err);
          });
      }
    },
    openRecurringDialog(type) {
      this.viewType = type; // Set the viewType property
      this.recurringDialog = true; // Open the dialog
    },
    async changeUserData() {
      if (this.allUser) {
        try {
          let res = await UserService2.findAll({ active: 1 });
          this.workSpaceUsers = res.length ? res : [];
        } catch (e) {
          this.workSpaceUsers = [];
        }
      } else {
        this.getUsers();
      }
    },
    cancel() {
      this.updateIssueStatuDialog = false
      this.recurringDialog = false;
      this.adminDialog = false;
      this.$emit('reload-issue-data')
    },
    closeDialog() {
      this.dialogVisible = false;
    },
    async checkBrowser() {
      let res = await IssueService.getIssueData({
        issue_id: this.issueData.id,
      });
      this.localData = res[0].ds_issue_list.stage;
      console.log(this.localData, "resData");
      if (this.localData === "inprogress") {
        this.dialogVisible = true;
      } else {
        this.dialogVisible = false;
      }
    },

    checkRecurring() {
      if (this.recurringTasks.length > 0) {
        console.log("cancel Remove function ")  // revove by  bala for multiple account,project
        // this.menuOptions = [{ code: "remove", name: "Remove recurring task" }];
        let index = this.menuOptions.findIndex(k=>k.code =='recurring')
        if(index>-1){
          this.menuOptions.splice(index,1)
        }

      } else {
        this.menuOptions = [
          { code: "recurring", name: "Setup recurring task" },
          // { code: "followup", name: "Add Followup" },
          // { code: "itSupportfollowup", name: "Add IT Support Followup" },
        ];
      }
      let $user = localStorage.getItem("user_details");
      $user = JSON.parse($user);
      if ($user.roles.role_type <= 6) {
        let qaIndex = this.menuOptions.findIndex(k=>k.code =='qa')
        if(qaIndex<=-1){
          this.menuOptions.push({ code: "qa", name: "Toggle QA Required" });
        }
        if (this.issueData && this.issueData.stage == "inprogress") {
          this.menuOptions.push({ code: "admin", name: "Stop Timer" });
        }
      }
    },
    async hasRecurringTask() {
      let $recurring = await JobsService.findAll({id:this.issueData.recurring_task_id,active:1});
      this.recurringTasks = $recurring;
      this.checkRecurring()
    },
    menuOptionSelected($selected) {
      if ($selected.code == "admin") {
        this.adminDialog = true;
      } else if ($selected.code == "recurring") {
        this.recurringDialog = true;
        this.viewType = false;
      } else if ($selected.code == "remove") {
        this.removeRecurringTask();
      } else {
        this.$emit("extended-menu-selected", $selected);
      }
      console.log($selected);
    },
    removeRecurringTask() {
      let obj = {
        id: this.recurringTasks[0].id,
        active: 0,
      };
      JobsService.update(obj)
        .then((res) => {
          console.log(res);
          this.$store.dispatch("setSnackbar", { text: "Updated Successfully" });
        })
        .catch((e) => {
          console.log(e);
          this.$store.dispatch("setSnackbar", {
            color: "error",
            text: "Something Went Wrong",
          });
        });
    },
    getUserName($item) {
      return $item.first_name && $item.last_name
        ? `${$item.first_name} ${$item.last_name}`
        : $item.first_name;
    },

    goback() {
      if (this.issueData.parent_issue_id) {
        let id = this.issueData.parent_issue_id;
        this.$store.dispatch("setIssueInView", id);
      } else {
        this.$emit("closedrawer");
      }
    },
    toggleFollowing() {
      //alert(JSON.stringify(this.userData,null,4))
      this.following = !this.following;
      this.$emit(
        "togglefollowing",
        this.userData.id,
        this.following,
        this.userData.username
      );
    },
    isFollowing() {
      let $following = this.totalFollowers.filter((obj) => {
        return obj.id == this.userData.id;
      });
      if ($following.length > 0) {
        this.following = true;
      } else {
        this.following = false;
      }
    },
    async getPriority() {
      let res = await EnumService.findAllDomain({ domain: "PRIORITY" });
      this.priorities = res;
    },
    async getTotalViews() {
      let $watchers = this.issueData.watchers ? JSON.parse(this.issueData.watchers) : [];

      if ($watchers.length > 0) {
        this.totalViews2 = await UserService.findAll({ id: $watchers });
      } else {
        this.totalViews2 = [];
      }
    },
    localeDate($date) {
      return new Date($date).toLocaleDateString("en-US", {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      });
    },

    updateData($item, $column, $value2) {
      console.log($item, $column, $value2, "hhhhhhhhhhhhhhhhhdddffffff");
      let $data = {
        id: this.issueData.id,
      };
      $data[$column] = $item;
      this.$emit("updateData", $data, $column, $value2);
    },
    updateStage($event) {
      this.adminDialog = false;
      this.updateIssueStatuDialog = false
      let $data = {
        id: this.issueData.id,
      };
      $data["stage"] = $event;
      console.log($data, "jjjjjjjjjjjjjeeeee", $event);
      this.$emit("updateData", $data, "stage");
    },
    toggleMenu(event, type) {
      if (type == "priority" && this.showEdit && this.issueData.stage != "closed") {
        this.$refs.priorityMenu.toggle(event);
      } else if (type == "stage" && this.showEdit) {
        this.$refs.childStage.toggle(event);
      } else if (
        type == "issueType" &&
        this.showEdit &&
        this.issueData.stage != "closed"
      ) {
        this.$refs.childIssueType.toggle(event);
      }
    },
    getStage($stage) {
      console.log(this.stages, $stage, "getting stage data");
      let opt = this.backupStage.filter((k) => k.code == $stage);
      return opt && opt[0] && opt[0].name ? opt[0].name : "";
    },
    async getAllUsers() {
      try {
        let res = await UserService.findAll();
        this.allUsers = res.length ? res : [];
      } catch (e) {
        this.allUsers = [];
      }
    },
    async getUsers() {
      try {
        let wsDefaults = JSON.parse(localStorage.getItem("_WS_DEFAULTS_"));
        let workSpaceId = localStorage.getItem("workSpaceId");
        let res = await UserService2.getNextAction({
          work_space_id: workSpaceId,
          account_id: wsDefaults.account.id,
          project_id: wsDefaults.project.id,
        });
        this.workSpaceUsers = res.length ? res : [];
      } catch (e) {
        this.workSpaceUsers = [];
      }
    },
    shareIssue($item) {
      let $data = {
        shared_by: {
          id: this.userData.id,
          name: this.getUserName(this.userData),
        },
        shared_with: {
          id: $item.id,
          name: this.getUserName($item),
        },
      };
      this.$emit("shared", $data);
    },
    nextAction($item) {
      let $data = {
        id: this.issueData.id,
        next_action: $item.id,
      };
      let $data2 = {
        next_action_name: this.getUserName($item),
      };
      this.$emit("updateData", $data, "next_action_id", $data2);
    },
    getBgColors(name) {
      if (name == "low") return "#D1FFBD";
      else if (name == "high") return "#FFCCCB";
      else if (name == "medium") return "#F9C644";
      else return "#F0ECFF ";
    },
  },
};
</script>
<style scoped>
.playContainer {
  left: -5px;
  padding: 10px;
  margin-right: 9px;
  position: relative;
  width: 98%;
  background-color: #f5fbfe;
  height: 53px;
  border: 1px solid rgb(224, 243, 253);
  border-radius: 4px;
  align-items: center;
}

.line-clamp {
  height: 24px;
  line-height: 24px !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  overflow: hidden;
}

.line-clamp:hover {
  height: auto;
}

.btn-info {
  border-color: #adadad;
}

.icon-label {
  color: #000;
  font-weight: 800;
}

.text-label {
  color: #272727;
  font-weight: 400;
  text-transform: capitalize;
}

.pink-bg {
  background-color: #f8d6e6 !important;
  opacity: 1 !important;
}

.purple-bg {
  background-color: #e2d6f8 !important;
}

.gold-bg {
  background-color: #f8f2d6;
}

.teal-bg {
  background-color: #d6f8f6;
}

.grey-bg {
  background-color: #f4f4f423;
}

.outlined {
  border: 1px solid #dfdfdf;
}

.bold-medium {
  font-weight: 600;
}

>>> ::-webkit-scrollbar {
  width: 6px;
}

>>> ::-webkit-scrollbar-track {
  background: #e8f3f9;
}

>>> ::-webkit-scrollbar-thumb {
  background: rgb(0, 0, 0, 0.12);
  border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #e8f3f9;
}
.small-switch {
  --v-input-control-height: 30px !important;
}
>>> .v-switch .v-selection-control {
  min-height: 0px !important;
}
>>> .v-selection-control__wrapper {
  width: 60px !important;
  height: 14px !important;
  display: inline-flex;
  align-items: center;
  position: relative;
  justify-content: center;
  flex: none;
}
>>> .v-input__details {
  align-items: flex-end;
  display: flex;
  font-size: 0.75rem;
  font-weight: 400;
  grid-area: messages;
  letter-spacing: 0.0333333333em;
  line-height: normal;
  min-height: 0px !important;
  padding-top: 0px !important;
  overflow: hidden;
  justify-content: space-between;
}
>>> .v-switch .v-label {
  padding-inline-start: 0px !important;
}
</style>
