<template>
    <v-card width="100%" style="border-radius: 12px; overflow: hidden">
      <v-form @submit.prevent="save" ref="form">
        <v-card-item>
          <v-card-title>Candidate Confirmation
            <v-btn @click="setFalse()" elevation="0" density="comfortable" icon="mdi-close" class="x-closeIcon"></v-btn>
          </v-card-title>
        </v-card-item>
  
        <v-card-text>
          <v-row no-gutters class="mt-3 pa-2">
                <v-col cols="12">
                    Please re- confirm! Do you need to move the <b>{{ data && data.first_name?data.first_name:'' }} {{ data && data.last_name?data.last_name:'' }}</b> to Envision employee
                </v-col>
           
          </v-row>
        </v-card-text>
        <v-card-action class="mr-3">
          <v-row justify="end" class="dialogBottom" v-if="!showReadOnly">
            <v-btn class="mr-2 cancel" @click="dialog = setFalse()">No</v-btn>
            <v-btn class="save" :loading="saveClicked" @click="$emit('onboard-confirmation',data)" >Yes</v-btn>
          </v-row>
        </v-card-action>
      </v-form>
    </v-card>
  </template>
  <script>
  export default {
    props:["data"],
    methods:{
      setFalse(){
        this.$emit('close')
      }
    }
  }
</script>