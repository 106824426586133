<template>
  <v-card class="pa-2" width="100%">
    <v-form @submit.prevent="save" ref="form">
      <v-card-item>
        <v-card-title
        >{{
          viewType == "edit" ? "Edit" : viewType == "view" ? "View" : "Add"
        }}
        <b>Form builder</b>
        <v-btn style="float: right" @click="setFalse" elevation="0" density="comfortable" icon="mdi-close-circle"></v-btn>
    
  </v-card-title>
   </v-card-item>

   
   <v-card-text >
        <v-row no-gutters class="pa-0 ">
          <v-col cols="5">
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
              >Code
              <span
                v-if="!showReadOnly"
                style="color: #cf0707; padding-left: 2px; font-weight: 700"
                >*</span
              ></v-label
            >
            <TextFieldComponent
              density="compact"
              placeholder="Code"
              :rules="codeRule"
              v-model="formData.code"
              :disabled="showReadOnly"
              @keyup="checkCode()"
              @keydown="prevent($event)"
            />
            <v-error v-if="codeError">Already Exists!</v-error>
          </v-col>
        </v-row>
        <v-row no-gutters class="">
          <v-col cols="12">
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
              >Name
              <span
                v-if="!showReadOnly"
                style="color: #cf0707; padding-left: 2px; font-weight: 700"
                >*</span
              ></v-label
            >
            <TextFieldComponent
              density="compact"
              placeholder="Name"
              :rules="nameRule"
              v-model="formData.name"
              :disabled="showReadOnly"
            />
          </v-col>
        </v-row>
        <v-row no-gutters class="">
          <v-col cols="6" class="dropdown">
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
              >Form Type [E]
              <span
                v-if="!showReadOnly"
                style="color: #cf0707; padding-left: 2px; font-weight: 700"
                >*</span
              ></v-label
            >
            <EnumAutoCompleteVue
              density="compact"
              :rules="formRule"
              v-model="formData.form_type"
              :showReadOnly="true"
              :selected="formData.form_type"
              :domain="'FORM_TYPE'"
              placeholder="Form Type"
              @changeEnumData="changeFormtype($event)"
            />
          </v-col>
        </v-row>
        <v-row no-gutters class="">
          <v-col cols="6" class="dropdown">
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
              >Interface Type [E]
              <span
                v-if="!showReadOnly"
                style="color: #cf0707; padding-left: 2px; font-weight: 700"
                >*</span
              ></v-label
            >
            <EnumAutoCompleteVue
              density="compact"
              :rules="interfaceRule"
              v-model="formData.interface_type"
              :showReadOnly="true"
              :selected="formData.interface_type"
              :domain="'INTERFACE_TYPE'"
              placeholder="Interface Type"
              @changeEnumData="changeInterface($event)"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-action >

        <v-row justify="end" no-gutters class="dialogBottom"  v-if="!showReadOnly">
          <v-btn class="mr-2 cancel" @click="dialog = setFalse()">Cancel</v-btn>
          <v-btn class="save" type="submit">Save</v-btn>
        </v-row>
      </v-card-action>
    </v-form>
  </v-card>
</template>

<script>
import TextFieldComponent from "@/components/TextFieldComponent.vue";
// import FormsService from "@/api/service/VacationTemplate/FormsService";
import FormsService from "@/api/service/FormBuilder/FormsService";
import EnumAutoCompleteVue from "@/components/others/EnumAutoComplete.vue";
export default {
  props: ["id", "viewType"],
  components: {
    TextFieldComponent,
    EnumAutoCompleteVue
    // VacationTemplateDetails
  },

  data() {
    return {
      showReadOnly: false,
      codeRule: [(value) => !!value || "Code is Required"],
      nameRule: [(value) => !!value || "Name is Required"],
      formRule: [(value) => !!value || "Form type is Required"],
      interfaceRule: [(value) => !!value || "Interface type is Required"],
      dialog: false,
      codeError: false,
      type: null,
      formData: {
        interface_type:"Dialog",
        form_type:"Master detail"
      },
    };
  },

  watch: {
    id() {
      if (this.id) {
        this.formData = {};
        this.getById(this.id);
      }
    },
  },

  created() {
    this.getVacationTemplateDataData();
    if (this.id) {
      this.getById(this.id);
    }
    if (this.viewType) {
      this.showReadOnly = this.viewType == "edit" ? false : true;
    }
  },

  methods: {
    prevent(e) {
        if (!/^[a-zA-Z0-9-_ \t]+$/.test(e.key) && e.key !== "Backspace") {
          e.preventDefault();
        }
      },
    checkCode() {
          if(this.formData.code && this.formData.code.length>0){
          this.formData.code = this.formData.code.toUpperCase();
          FormsService.findAll({ code: this.formData.code }).then((res) => {
            let $exist = res.filter(key => key.code == this.formData.code)
            this.codeExistFlag = $exist.length > 0 ? (this.formData.id && $exist[0].id == this.formData.id) ? false : true : false;
            if (this.codeExistFlag) {
              this.codeError = true
            }
            else {
              this.codeError = false
            }
          });
        }
    },
    changeFormtype($event){
      this.formData.form_type=$event
    },
    changeInterface($event){
      this.formData.interface_type=$event
    },
    setFalse() {
      this.$emit("closeDialog");
    },
    async getById(id) {
      let res = await FormsService.findOne(id);
      this.formData = res[0];
    },
    async getVacationTemplateDataData() {
      let res = await FormsService.findAll();
      this.vacationTemplateData = res.length > 0 ? res : [];
    },

    async save() {
      let validation = await this.$refs.form.validate()
      if (!validation.valid || this.codeError) {
        console.log(validation.valid)
      }
      else {
        if (this.formData.id) {
          let output = await FormsService.update(this.formData);
          console.log(output,'output')
          this.dialog = false;
          this.setFalse();
          this.$emit("reload", true);
          this.formData = [];
         
        } else {
          let output = await FormsService.create(this.formData);
          console.log(output, "gfdgg");
          this.dialog = false;
          this.setFalse();
          this.$emit("reload", true);
          this.formData = [];
          this.$router.push({ path: "/formBuilder", query: { id: output.id } });
        }
      }
    },
  },
};
</script>

<style scoped>
/* .save{
  color: #fff;
  width: fit-content;
  padding: 0 6rem;
} */
</style>