<template>
  <div v-for="(question, index) of QuestionnaireData" :key="index">
    <AnswerFormBuilder v-if="question.typeOfTheQuestion == 'dataentry'" :formSample='question.correctAnswer' class="pa-2"/>
    <QuestionFormBuilder v-if="question.typeOfTheQuestion == 'dataentry'" :formSample='question.answer'/>
    <!-- <v-card
      v-if="question.typeOfTheQuestion == 'dataentry'"
      style="background: #eeeeee; border: 1px solid #d9d9d9"
      class="mb-2"
      flat
      dense
    >
      <v-row noGutters>
        <v-col cols="6">
          <div class="head-title">Answer</div>
          <HtmlToImage :sampleId="26" :sampleData="returnAnswer(question.answer)" />
        </v-col>
        <v-col cols="6">
          <div class="head-title">Question</div>
          <HtmlToImage :sampleId="26" :sampleData="returnQuestion(question.correctAnswer)" />
        </v-col>
        <v-col cols="12" class="pa-2 bg-white">
          <TextFieldComponent v-model="dataEntryMark" type="number"/>
          <v-btn class="save " type="submit">Save</v-btn>
        </v-col>

      </v-row>
    </v-card> -->
    <v-card v-if="question && question.typeOfTheQuestion && question.evaluatedAnswer == null && question.typeOfTheQuestion =='freeform'"
      style="background: #eeeeee; border: 1px solid #d9d9d9"
      class="mb-2"
      flat
      dense
    >
      <v-card-text>
        <v-row>
          <v-col cols="12" md="12">
            <span class="text-body-2 font-weight-medium">
              {{ question.question }}
            </span>
          </v-col>
          <v-col cols="12" md="12">
            <div
              class="text-body-2 font-weight-medium pa-6"
              style="background: #f8f8f8; border-radius: 4px"
            >
              {{
                question && Array.isArray(question.answer)
                  ? question.answer.join(", ")
                  : getAnswer(question.answer)
              }}
            </div>
          </v-col>
          <v-col cols="12" md="12" class="pt-0">
            <v-row>
              <v-col
                cols="12"
                md="10"
              >
              <!-- question.typeOfTheQuestion == type ||
                  (!type &&
                    question.typeOfTheQuestion == 'freeform' &&
                    question.evaluatedAnswer !=null) -->

              <div v-if="
                 question.typeOfTheQuestion == 'freeform' &&
                    question.evaluatedAnswer ==null
                ">
                <span
                  :style="{
                    background: question.evaluatedAnswer == 1 ? '#90eca7' : '',
                  }"
                  style="border: 1px solid #a7a7a7"
                  class="pa-2 text-body-2 font-weight-medium cursor-pointer evaluateCorrect"
                  @click="updateFreeform(question, 1)"
                  >Correct</span
                >
                <span
                  :style="{
                    background: question.evaluatedAnswer == 0 ? '#f1b1b1' : '',
                  }"
                  style="border: 1px solid #a7a7a7"
                  class="pa-2 text-body-2 font-weight-medium cursor-pointer evaluateIncorrect"
                  @click="updateFreeform(question, 0)"
                  >Incorrect</span
                >
              </div>
              </v-col>
              <v-col
                cols="12"
                md="10"
                v-if="!type && question.typeOfTheQuestion !== 'freeform'"
                class="pt-0"
              >
                <span
                  class="text-body-2 font-weight-medium"
                  style="color: green"
                >
                  Correct Answer:
                </span>
                <span class="text-body-2 font-weight-medium">
                  {{
                    question && Array.isArray(question.correctAnswer)
                      ? question.correctAnswer.join(", ")
                      : question.correctAnswer
                  }}
                </span>
              </v-col>
              <v-col :class="question.typeOfTheQuestion !='freeform'?'pt-0':''">
                <CustomAvatar2
                  :size="'small'"
                  class="d-inline-flex mr-2"
                  :theme="question.evaluatedAnswer == 1 ? '#90eca7' : '#f1b1b1'"
                  v-if="!type"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import AssessmentAnswerService from "@/api/service/AssessmentAnswers/AssessmentAnswerService";
// import HtmlToImage from "@/modules/settings/Hr/EvaluvationSheet.Vue/HtmlToImage.vue";
// import TextFieldComponent from "@/components/TextFieldComponent.vue";
import AnswerFormBuilder from "./AnswerFormBuilder.vue";
import QuestionFormBuilder from "./QuestionFormBuilder.vue";

// import CustomAvatar2 from "@/components/ui/CustomAvatar2.vue";

export default {
  props: ["QuestionnaireData", "type"],
  components: {
    // CustomAvatar2,
    // HtmlToImage,
    // TextFieldComponent,
    AnswerFormBuilder,
    QuestionFormBuilder
  },
  data() {
    return {
      dataEntryMark:0,
      formSample:null
    };
  },
  watch:{
    QuestionnaireData(){
      console.log(this.QuestionnaireData,'dddddddddddddd'); 
     return this.QuestionnaireData
    }
  },
  methods: {
    returnQuestion(data) {
      data = data ? JSON.parse(data) : {};
      console.log(data);
      return data;
    },
    returnAnswer(data) {
      data = data ? JSON.parse(data) : {};
      console.log(data);
      let sampleData = data && data.sample_data ? JSON.parse(data.sample_data) : {}
      return sampleData;
    },
    getAnswer(data){
      try{
        if(data){
          return JSON.parse(data)
        }
      }
      catch(e){
        return data
      }
    },
    refreshSummary() {
      let reload = this.$store.getters.getRefreshSummary;
      this.$store.commit("SET_SUMMARY_RELOAD", !reload);
    },
    async updateFreeform(question, evaluated) {
      let output = await AssessmentAnswerService.update({
        id: question.id,
        evaluated_answer: evaluated,
      });
      console.log(output);
      this.refreshSummary();
    },
  },
};
</script>
<style scoped>
.evaluateCorrect:hover {
  background: #90eca7;
}
.evaluateIncorrect:hover {
  background: #f1b1b1;
}
</style>
