<template>
      <v-card justify-center flat class="center pa-3 pt-3 " 
      :width="activeSessions && activeSessions.length>=0 ? '400px' : '600px'" >
        <div class="header">
          <img
            :src="require('@/assets/image/Search.svg')"
            width="120"
            height="120"
          />
        </div>
        <v-card-text>
          <div v-for="(item, index) in activeSessions" :key="index">
            <v-row class="header">
              <v-col cols="11" class="py-0">
              
                Found an active session on   {{  $store.getters.getDeviceId!=item.device_id  ? 'another' : 'this'}} device
              </v-col>
              <v-col cols="12" class="py-3">
                <span class="text-h6"
                v-if="item.start_date"><small>Started on </small><br/>
                  {{item && item.start_date ? moment(item.start_date ).format("DD-MM-YYYY hh:mm A"): '' }}</span
                >
              </v-col>
              <v-col cols="12" class="py-0">
                <div>
                <span style="font-weight:600">Session Id</span> <br/>
                  <span class="text-disabled" >{{ item.sessionId }}</span><br /> <br/>
                  <span style="font-weight:600">Device Info.</span> <br/>
                  <span class="text-disabled" style="line-height:32px">{{ item.deviceId }} </span><br />
                  <span class="text-disabled">{{ item.deviceInfo }} </span>
                </div>
              </v-col>
              <v-col cols="12" style="color:#636363" v-if="item.proposed_end_date">
                Your Shift ends at <br />
                <span style="font-size: 16px; font-weight: 600" >{{item && item.proposed_end_date ? moment(item.proposed_end_date ).format("DD-MM-YYYY hh:mm A"): '' }}</span>
              </v-col>
              
              <v-col cols="12" class="text-center">
                  <v-btn
                  class="logOut"
                  color="red"
                  :loading="loading"
                  :disabled="loading"
                  @click="logoutOtherDevices"
                >
                  Logout
                </v-btn>          
                      <br/><br/>
                <span>Logout from other devices and continue this session </span>
              </v-col>
              <!-- <v-col cols="12">
                
              </v-col> -->
              
            </v-row>
          </div>
        </v-card-text>
        </v-card>
</template>
<script>
import moment from 'moment-timezone'
export default {
    props:["activeSessions","userData"],
    data() {
      return {
        loading: false,
      };
    },
    methods:{
        moment,
        logoutOtherDevices() {
          this.loading =true
            this.$emit('sessioncleared',this.userData)
        }
    }
}
</script>
<style scoped>
  .center {
    display:block;
    margin:auto auto;
    margin-top:40px;
  }
  .header {
    display: flex;
    text-align: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 400;
  }
  .box {
    background-color: #f4f4f4;
    margin-left: 250px;
    margin-right: 250px;
    /* width: fit-content; */
    border-radius: 6px;
    height: fit-content;
    display: flex;
  }
  .logOut {
    width: 100px;
    height: 32px;
    border-radius: 8px;
    color: white;
    font-weight: 600;
    background: #1258ab;
  }
  .Icon {
    display: flex;
    align-items: center;
    margin-right: 12px;
  }
  </style>