<template>
  <v-card elevation="0" style="border-radius: 10px">
    <v-form @submit.prevent="save" ref="form">
      <v-card-item>
        <v-card-title>
          {{
            this.type === "action" && !showReadOnly
              ? "Edit"
              : showReadOnly
              ? "View"
              : "Add"
          }}
          Follow Up
          <v-avatar size="x-small" color="primary">
            {{ no_of_followups }}
          </v-avatar>
          <v-btn
            style="float: right"
            @click="close()"
            elevation="0"
            density="comfortable"
            icon="mdi-close"
          ></v-btn>
        </v-card-title>
      </v-card-item>

      <v-card-text>
        <v-row no-gutters>
          <v-col cols="12" class="px-0 py-0" style="display: grid">
            <v-radio-group
              :disabled="showReadOnly"
              v-model="formData.followup_type"
              inline
            >
              <v-radio color="primary" label="Internal" :value="1"></v-radio>
              <v-radio color="error" label="External" :value="0"></v-radio>
            </v-radio-group>
          </v-col>

          <v-col cols="4">
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
              >FollowUp Date
              <span
                v-if="!showReadOnly"
                style="color: #cf0707; padding-left: 2px; font-weight: 600"
                >*</span
              ></v-label
            >
            <TextFieldComponent
              density="compact"
              :showReadOnly="showReadOnly"
              placeholder="FollowUp date"
              :rules="dateRule"
              v-model="formData.followup_date"
              type="date"
            />
          </v-col>

          <v-col cols="12">
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
              >Description</v-label
            >
            <DescriptionVue
              class="pb-0"
              :showReadOnly="showReadOnly"
              editorStyle="height: 120px"
              :data="formData.description"
              @textData="changeDescription($event)"
              ref="descriptionVue"
            ></DescriptionVue>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-action class="mr-3" v-if="!showReadOnly">
        <v-row justify="end" class="dialogBottom">
          <v-btn style="--v-btn-height: 35px" class="mr-2 cancel" @click="close()"
            >Cancel</v-btn
          >
          <v-btn
            style="--v-btn-height: 35px"
            :loading="saveClicked"
            class="save"
            type="submit"
            >Save</v-btn
          >
        </v-row>
      </v-card-action>
    </v-form>
  </v-card>
</template>

<script>
import DescriptionVue from "@/components/ui/Description.vue";
import IssueService from "@/api/service/Issue/IssueService";
import ForumService from "@/api/service/Forum/ForumService";
import moment from "moment";

import TextFieldComponent from "@/components/TextFieldComponent.vue";
export default {
  components: {
    DescriptionVue,
    TextFieldComponent,
  },
  props: ["issueId", "no_of_followups", "type", "showReadOnly"],
  data() {
    return {
      saveClicked: false,
      formData: {
        followup_date: null,
        followup_type: 1,
        description: null,
      },
      dateRule: [(value) => !!value || "Follow up is Required"],
    };
  },

  created() {
    if (this.issueId && this.type && this.type == "action") {
      this.getById(this.issueId);
    }
  },
  methods: {
    moment,
    close() {
      this.$emit("closeDialog");
    },
    changeClarification($event) {
      this.formData.clarification = $event;
    },
    changeDescription($event) {
      this.formData.description = $event;
    },
    async getById(id) {
      let res = await IssueService.getStageData({
        issueId: id,
        type: "followup",
      });
      if (res && res.length > 0) {
        this.formData.followup_type =
          res[0].followup_type && res[0].followup_type == "internal" ? 1 : 0;
        this.formData.followup_date = res[0].followup_date
          ? moment(res[0].followup_date).format("YYYY-MM-DD")
          : null;
        this.formData.description =
          res[0].forums && res[0].forums.description ? res[0].forums.description : null;
        this.formData.f_id =
          res[0].forums && res[0].forums.f_id ? res[0].forums.f_id : null;
      }
    },

    async save() {
      let validation = await this.$refs.form.validate();
      if (!validation.valid) {
        console.log(validation.valid);
      } else {
        if (this.issueId) {
          if (!this.formData.description) {
            this.$refs.descriptionVue.showErrorMessage("Description is Required");
            return;
          }
          this.saveClicked = true;
          let followup;
          if (this.formData.followup_type == 1) {
            followup = "internal";
          } else {
            followup = "external";
          }
          let $obj = {
            id: this.issueId,
            stage: "followup",
            followup_type: followup,
            followup_date: this.formData.followup_date,
          };
          let $issueStatus = this.$store.getters.getIssueStatus;				
          await IssueService.update($obj);
          if($issueStatus && $issueStatus ==1){
						this.$store.commit("SET_ISSUE_STATUS",2);
					}
          let $description =
            this.formData && this.formData.description ? this.formData.description : "";

          if (this.type && this.type == "action") {
            let data1 = {
              id: this.formData.f_id ? this.formData.f_id : null,
              key_type: "issue",
              key_id: this.issueId,
              action: "followup",
              description: $description,
            };
            console.log(data1, "data1");
            await ForumService.update(data1);
          } else {
            let data = {
              key_type: "issue",
              key_id: this.issueId,
              action: "followup",
              description: $description,
            };
            await ForumService.create(data);
          }
          this.$emit("saveDialog");
          this.$emit("followup-updated");
          this.close();

          this.formData = {};
        }
      }
    },
  },
};
</script>

<style></style>
