<template>
  <!-- <div>
    <AutoComplete v-model="selected" :items="enumData" item-title="display_value" item-value="data_value"/>
  </div> -->

  <PrimeDataTable
    :title="'Blocked Calender'"
    :showFilter="true"
    :searchOption="true"
    :selectAll="true"
    :showDownload="true"
    :showUpload="false"
    :showAdd="true"
    :showSwitch="true"
    :columnData="columns"
    :data="data"
    @switchData="getSwitchData"
    @addDiolog="addDiolog()"
    @rowAction="rowActions($event)"
    activity="BlockedCalender"
  />
  <v-dialog persistent v-model="dialog" width="800px">
    <BlockedCalenderAdd
      ref="BlockedCalenderAdd"
      @reload="getBlockedCalendarData()"
      @closeDialog="setFalse()"
      :id="blockedCalendarId"
      :viewType="viewType"
    />
  </v-dialog>
</template>

<script>
// import AutoComplete from '../ui/AutoComplete.vue'
import BlockedCalendarService from "@/api/service/BlockedCalendar/BlockedCalendarService";
import PrimeDataTable from "@/components/PrimeDataTable";
import BlockedCalenderAdd from "./BlockedCalenderAdd.vue";
import commonService from "@/common.service";
import moment from "moment";
export default {
  components: {
    // AutoComplete,
    PrimeDataTable,
    BlockedCalenderAdd,
  },
  props: ["domain"],
  data() {
    return {
      data: [],
      columns: [],
      date: new Date(),
      blockedCalendarId: "",
      dialog: false,
      viewType: false,
      dataBackup:[]
    };
  },
  created() {
    this.loadColumns();
    this.download = true;
    this.search = true;
    this.checkbox = true;
    this.getBlockedCalendarData();
  },
  methods: {
    loadColumns() {
      this.columns = [
        // {
        //   field: "account_name",
        //   header: "Account",
        //   style: { width: "100px", color: "" },
        //   toolTip: "Code",
        //   sortfield: true,
        // },
        {
          field: "subject",
          header: "Subject",
          style: { width: "100px", color: "" },
          toolTip: "Name",
          sortfield: true,
        },
        {
          field: "reason",
          header: "Reason",
          style: { width: "100px", color: "" },
          toolTip: "Name",
          sortfield: true,
        },
        {
          field: "from_date",
          header: "From Date",
          type: "date",
          style: { width: "100px", color: "" },
          toolTip: "Name",
          sortfield: true,
        },
        {
          field: "to_date",
          type: "date",
          header: "To Date",
          style: { width: "100px", color: "" },
          toolTip: "Name",
          sortfield: true,
        },

        {
          field: "status",
          header: "Status",
          style: { width: "100px", color: "" },
          toolTip: "Status",
          sortfield: true,
        },
        {
          field: "action",
          header: "Action",
          style: { width: "100px", color: "" },
          toolTip: "Action",
          sortfield: true,
          rowActions: [{ action: "view" }, { action: "edit" }],
        },
      ];
    },

    setFalse() {
      this.dialog = false;
    },

    rowActions($event) {
      if ($event.type == "view") {
        this.dialog = true;
        this.blockedCalendarId = $event.data.id;
        this.viewType = $event.type;
      }
      if ($event.type == "edit") {
        this.dialog = true;
        this.blockedCalendarId = $event.data.id;
        this.viewType = $event.type;
      }

      if ($event.type == "select") {
        this.dialog = true;
        this.blockedCalendarId = $event.data.id;
        this.viewType = "view";
      }
    },
    getSwitchData($event) {
      if($event == 'active'){
        this.data = this.dataBackup.filter(value => value.active === 1)
      }else if($event == 'inactive'){
        this.data = this.dataBackup.filter(value => value.active === 0)
      }else{
        this.data = this.dataBackup
      }
    },
    async getBlockedCalendarData() {
      let res = await BlockedCalendarService.getList();
      this.data = res;
      this.dataBackup = [...this.data]
      console.log(this.data, "dataaaaaaaaa");
      if (this.data && this.data.length > 0) {
        this.data.forEach(async (value) => {
          let currentDate = moment(new Date()).format("YYYY-MM-DD");
          let reqDate = moment(value.to_date).format("YYYY-MM-DD");
          if (reqDate < currentDate) {
            await BlockedCalendarService.update({ id: value.id, active: 0 });
          }
        });
      }
      this.getSwitchData('active')
    },
 
    addDiolog() {
      this.dialog = true;
      this.blockedCalendarId = null;
      this.viewType = false;
    },
  },

  addFilter() {
    this.$refs.FilterDrawer.dialog = true;
    this.$refs.FilterDrawer.size = commonService.largeDialog();
  },
};
</script>

<style></style>
